/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";

import { useParams } from "react-router-dom";

//import translation function for language translation
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import {getData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

//import context
import { GlobalProvider } from "context/GlobalContext";


const ViewReserveSchedulePopup = () => {

    const token = localStorage.getItem("token"); // token
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    /* ========== get user id from url params ========= */
    const params = useParams();

    const { t } = useTranslation(); //for translation

    const { setReloadCalendarEvents, editCalendarData } = useContext(GlobalProvider);

    const [reserveData, setReserveData] = useState("");


    //function for get schedule details
    const getScheduleDetails = async (schedulId) => {
        try {
        let requestUrl = `${url.API_BASEURL}${url.API_GET_SCHEDULE_DETAILS}/${schedulId}?token=${token}`;

        console.log(requestUrl);

        const response = await getData(requestUrl);

        console.log("response getScheduleDetails getScheduleDetails", response);

        if (response.status) {
            setReserveData(response.data?.reserveid??'')
        }
        } catch (error) {
        console.log(error.message);
        }
    };



    /* Hook to assign edit data */
    useEffect(() => {
        if (editCalendarData.length > 0) {
        getScheduleDetails(editCalendarData[0]);
        }
    }, [editCalendarData]);

    // return a modal with schedule reserve informations
    return (
        <div className="modal fade" id="viewReserveSchedulePopup" tabIndex={-1} aria-labelledby="viewReserveSchedulePopupLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="viewReserveSchedulePopupLabel">
                            {t('View Reserve Schedule')}
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body">
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="reserveDate">{t('Customer Id')}</label>
                                    <p>{reserveData?.value??''}</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="reserveDate">{t('Reserve Date')}</label>
                                    <p>{reserveData?.reservedate??''}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="reserveStatus">{t('Activity')}</label>
                                    <p>{reserveData?.activityname??''}</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="reserveShift">{t('Location')}</label>
                                    <p>{reserveData?.address??''}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="reserveLocation">{t('Grade')}</label>
                                    <p>{reserveData?.grade??'' }</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="reserveAvailability">{t('Beneficiary')}</label>
                                    <p>{reserveData?.beneficiaries?.[0]?.name??''}</p>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="reserveLocation">{t('Comments')}</label>
                                    <p>{reserveData.comments}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{t('Close')}</button>
                    
                        <button 
                            type="button" 
                            className="btn btn-primary" 
                            onClick={() => {
                                const offcanvas = new window.bootstrap.Offcanvas(
                                    document.getElementById("editSchedulePopup")
                                );
                                offcanvas.show();
                            }} 
                            data-bs-dismiss="modal"
                        >
                            Edit
                        </button>
                    </div>
                    
                </div>
            </div>
        </div>
    );

}

export default ViewReserveSchedulePopup;