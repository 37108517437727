/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
/* Importing react select */
import Select from "react-select";
//import translation function for language translation
import { useTranslation } from "react-i18next";
//import context
import { GlobalProvider } from "context/GlobalContext";
/*import url and gateway methods */
import { getData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";
const AgendaFilter = () => {
  const token = localStorage.getItem("token"); // token
  const { t } = useTranslation(); //for translation
  const {
    setfilterAgendaIncludeClientId,
    setfilterAgendaExcludeClientId,
    setReloadAgenda,
    setFilterAgendaEducatorTags
  } = useContext(GlobalProvider);
  const [clientList, setClientList] = useState([]); //client option
  const [includeClientValue, setIncludeClientValue] = useState(null);
  const [includeClientId, setIncludeClientId] = useState(null);
  const [excludeClientValue, setExcludeClientValue] = useState(null);
  const [excludeClientId, setExcludeClientId] = useState(null);

  const [labelList, setlabelList] = useState([]); // for label list
  const [tagValue, setTagValue] = useState('');
  const [tagData, setTagData] = useState([]);


  /* function to get all labels */
  const getAllLabels = async () => {
    let requestUrl =
      url.API_BASEURL +
      url.API_GET_ALL_TAG +
      `?token=${token}&isselect=${true}`;

    const response = await getData(requestUrl);

    if (response.status) {
      setlabelList(response.data);
    }
  };


  /* function to get all client list */
  const getALlClientList = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_USER +
        `?token=${token}&isselect=${true}&slug=client`;
      const response = await getData(requestUrl);
      if (response.status) {
        setClientList(response.data);
        // setBenificiaryList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  //include client select
  const includeClientSelectionHandler = (val) => {
    if (val) {
      setIncludeClientValue(val);
      setIncludeClientId(val.value);
    } else {
      setIncludeClientValue(null);
      setIncludeClientId(null);
    }
  };
  //exclude client select
  const excludeClientSelectionHandler = (val) => {
    if (val) {
      setExcludeClientValue(val);
      setExcludeClientId(val.value);
    } else {
      setExcludeClientValue(null);
      setExcludeClientId(null);
    }
  };
  //reset
  const resetHandler = () => {
    setIncludeClientValue(null);
    setIncludeClientId(null);
    setExcludeClientValue(null);
    setExcludeClientId(null);
    setfilterAgendaIncludeClientId(null);
    setfilterAgendaExcludeClientId(null);
    setTagValue('');
    setTagData([]);
    setFilterAgendaEducatorTags([]);
    setReloadAgenda(true);
  };
  //serach filter
  const filterSearchHandler = () => {
    includeClientId
      ? setfilterAgendaIncludeClientId(includeClientId)
      : setfilterAgendaIncludeClientId(null);

    excludeClientId
      ? setfilterAgendaExcludeClientId(excludeClientId)
      : setfilterAgendaExcludeClientId(null);

    tagData.length > 0 ? setFilterAgendaEducatorTags(tagData) : setFilterAgendaEducatorTags([]);
  };

  useEffect(() => {
    getALlClientList();
    getAllLabels();
  }, []);
  return (
    <div className="collapse mt-3 user_filter" id="filterCollapse">
      <div className="p-3 bg-gray-200">
        <h4 className="mb-3">{t("Filter")}</h4>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="user_filter_outer">
            <div className="form-group mb-3">
              <div className="row">
                <div className="col-lg-4">
                  <Select
                    options={clientList}
                    placeholder={t("Select client for includes")}
                    value={includeClientValue}
                    onChange={(val) => {
                      includeClientSelectionHandler(val);
                    }}
                  />
                </div>
                <div className="col-lg-4">
                  <Select
                    options={clientList}
                    placeholder={t("Select Client for excludes")}
                    value={excludeClientValue}
                    onChange={(val) => {
                      excludeClientSelectionHandler(val);
                    }}
                  />
                </div>
                <div className="col-lg-4">
                  <Select
                    placeholder={t("Select tag")}
                    className="nosearch_select"
                    isMulti
                    options={labelList}
                    value={tagValue}
                    onChange={(val) => {
                      setTagValue(val);
                      setTagData(val.map((label) => label.value));
                    }}
                  />
                </div>
              </div>
            </div>
            {/* button */}
            <div className="form-group mb-3">
              <div className="action d-flex gap-3 align-items-center justify-content-end">
                {/* ------- reset button start ------- */}
                <Link
                  to="#"
                  className="d-flex gap-1 align-items-center text-black"
                  onClick={resetHandler}
                >
                  <span className="d-block material-symbols-outlined icon-md">
                    sync
                  </span>
                  <span className="d-block fw-medium">{t("Refresh")}</span>
                </Link>
                {/* ------- reset button end ------- */}
                {/* ------- search button start ------- */}
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={filterSearchHandler}
                >
                  {t("Search")}
                </button>
                {/* ------- reset button end ------- */}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default AgendaFilter;
