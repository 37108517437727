/*=========== general URL start here ============*/

/*---------------- Prod server in Lobees for Email ip of AMB ------------------- */
export const API_BASEURL = "https://api.funbrain.lobees.com/api";
export const SERVER_URL = "https://api.funbrain.lobees.com";

// ----------------- in Funbrain server -----------------
// export const API_BASEURL = "https://apipro.funbrain.lobees.com/api";
// export const SERVER_URL = "https://apipro.funbrain.lobees.com";

// export const API_BASEURL = "https://apidev.funbrain.lobees.com/api";
// export const SERVER_URL = "https://apidev.funbrain.lobees.com";

/*--------------- local server url dev ------------------- */
// export const API_BASEURL = "http://localhost:4000/api";
// export const SERVER_URL = "http://localhost:4000";

// URL to upload Image in Text Editor (AskQuestions.js) component.
export const URL_TEXT_EDITOR_IMAGE_UPLOAD = "";

export const URL_TEXT_EDITOR_IMAGE_UPLOAD_2 = "";

export const API_FILE_UPLOAD = "/upload/singleupload"; //for single file upload

export const API_FILE_UPLOAD_MULTIPLE = "/upload/multiupload"; //for multiple file upload

export const API_PDF_UPLOAD_ATTACH = "/upload/uploadpdf"; //for create PDF and attach in mail

export const API_UPLOAD_RESERVE_CSV = "/reserveevent/bulkcsvupload";

export const API_UPLOAD_ACTIVITY_CSV = "/reserveactivity/bulkcsvupload";

export const API_UPLOAD_KIT_CSV = "/materialkit/bulkcsvupload";

// API Tags (to fetch all the tags and display in AddTagsModal.js)
export const API_ALL_TAGS = "/tag/getalltags";

//get all module list
export const API_GET_ALL_MODULES = "/appmodule/getallappmodules";

/*----- category url start ------*/
//create new category
export const API_ADD_NEW_CATEGORY = "/category/createcategory";

//get all Categories
export const API_GET_CATEGORIES = "/category/getcategories";

//update category
export const API_UPDATE_CATEGORIY = "/category/updatecategory";

//delete category
export const API_DELETE_CATEGORY = "/category/deletecategory";

/*----- category url end ------*/

//API get all roles
export const API_GET_ALL_ROLES = "/roles/getroles";

// API to create new tag
export const API_CREATE_NEW_TAG = "";

// API to submit a rating for a course
export const API_REVIEW_RATING = "";

// API to fetch all the reviews for a course
export const API_VIEW_REVIEWS = "";

/*--------- blog api start ----------*/
//Api for get blog category
export const API_BLOG_CATEGORY = "";

//Api for all blog lists
export const API_BLOG_LISTS = "";

//API for blog details
export const API_BLOG_DETAILS = "";
/*--------- blog api end ----------*/

/*-------- login and registration url start ----------*/
// API registration
export const API_REGISTARTION = "/auth/register";

// API forgot password
export const API_FORGOT_PASSWORD = "";

// API login url
export const API_LOGINURL = "/auth/login";

// API update userinfo
export const API_UPDATE_USERINFO = "/useraccount/updateuser";

// API validate user
export const API_VALIDATE_OTP = "/auth/validateotp";

// url for get logged in user details
export const API_GET_USER_DETAILS = "/useraccount/userdetails";

// API for contact Us slider
export const API_CONTACT_US = "";

/*-------- login and registration url end ----------*/

/*----- company url start ------*/
export const API_GET_COMPANY_LIST = "/company/getallcompanies";

/*----- company url end ------*/

/*-------- userlist url start --------*/
//add new user
export const API_ADD_NEW_USER = "/useraccount/addnewuser";

//update user
export const API_UPDATE_USER = "/useraccount/updateuser";

//get all user list url
export const API_GET_ALL_USER = "/useraccount/userlists";

//get all client list
export const API_GET_ALL_CLIENTS = "/useraccount/getclientlist";

//add tag to user
export const API_ADD_TAG_TO_USER = "/useraccount/addtagtouser";

//remove tag from user
export const API_REMOVE_TAG_FROM_USER = "/useraccount/removetagfromuser";

/*-------- userlist url end --------*/

/*-------- event url start ----------*/

//get all list of events
export const API_GET_ALL_EVENTS = "/event/getallevents";

/*-------- event url end ----------*/

/*-------- reserve course url start --------*/
//add new course
export const API_ADD_NEW_COURSE = "/reservecourse/createcourse";

//get all course
export const API_GET_ALL_COURSE = "/reservecourse/getallcourse";

//update a course
export const API_UPDATE_COURSE = "/reservecourse/updatecourse";

//delete a course
export const API_DELETE_COURSE = "/reservecourse/deletecourse";
/*-------- reserve course url end --------*/

/*-------- material kit url start --------*/
//add new material kit
export const API_ADD_NEW_MATERIAL_KIT = "/materialkit/addmaterialkit";

//get all material kit
export const API_GET_ALL_MATERIAL_KIT = "/materialkit/getallmaterialkit";

//material kit details
export const API_MATERIALKIT_DETAILS = "/materialkit/details";

//update material kit
export const API_UPDATE_MATERIAL_KIT = "/materialkit/updatekit";

//delete material kit
export const API_DELETE_MATERIAL_KIT = "/materialkit/deletekit";

//check if available
export const API_KIT_ISAVAILABLE = "/materialkit/isavailable";

/*-------- material kit url end --------*/

/*-------- activity url start --------*/
//save activity
export const API_ADD_ACTIVITY = "/reserveactivity/addactivity";

//get all activity
export const API_GET_ALL_ACTIVITY = "/reserveactivity/getallactivity";

//get activity details
export const API_GET_ACTIVITY_DETAILS = "/reserveactivity/getactivitydetails";

//update activity
export const API_UPDATE_ACTIVITY = "/reserveactivity/updateactivity";

//delete activity
export const API_DELETE_ACTIVITY = "/reserveactivity/deleteactivity";

/*-------- activity url end --------*/

/*-------- reserve event url start --------*/
//add event
export const API_ADD_RESERVE_EVENT = "/reserveevent/createevent";

//addconsecutiveevents
export const API_ADD_CONSECUTIVE_RESERVE_EVENTS =
  "/reserveevent/addconsecutiveevents";

//removeconsecutiveevents
export const API_REMOVE_CONSECUTIVE_RESERVE_EVENTS =
  "/reserveevent/removeconsecutiveevents";

//get all event
export const API_GET_ALL_RESERVE_EVENTS = "/reserveevent/getallevent";

//get event details
export const API_GET_RESERVE_DETAILS = "/reserveevent/geteventdetails";

//get all activity event
export const API_GET_ALL_ACTIVITY_EVENTS = "/reserveevent/getallactivityevents";

//get event histroy
export const API_GET_EVENT_HISTORY = "/reserveevent/geteventhistory";

//get all user email by events
export const API_GET_USER_EMAIL_BY_EVENT = "/reserveevent/getalluseremails";

//get all user email by events
export const API_GET_RESPONSIBLE_EMAIL_BY_EVENT =
  "/reserveevent/getallresponsiblemails";

//update event
export const API_UPDATE_RESERVE_EVENT = "/reserveevent/updateevent";

//change educator
export const API_CHANGE_EDUCATOR = "/reserveevent/changeeducator";

//delete event
export const API_DELETE_RESERVE_EVENT = "/reserveevent/deleteevent";

//add tag to event
export const API_ADD_TAG_TO_EVENT = "/reserveevent/addtagtoevent";

//get all event template list
export const API_GET_ALL_EVENT_TEMPLATE_LIST =
  "/reserveevent/getalleventtemplates";

//get template details
export const API_RESERVE_TEMPLATE_DETAILS = "/reserveevent/gettemplatedetails";

//update template
export const API_UPDATE_RESERVE_TEMPLATE = "/reserveevent/updateeventtemplate";

//get all user by cleint id
export const API_GET_ALL_USER_BY_COMPANY =
  "/reserveevent/getalluserbycompanyid";

/*-------- reserve event url end --------*/

/*-------- reserve tag/label url start --------*/
//create label
export const API_ADD_TAG = "/tag/createtag";

//get all label
export const API_GET_ALL_TAG = "/tag/getalltags";

//update label
export const API_UPDATE_TAG = "/tag/updatetag";

//delete label
export const API_DELETE_TAG = "/tag/deletetag";

/*-------- reserve tag/label url end --------*/

/*-------- schedule url start --------*/
//add new schedule
export const API_ADD_NEW_SCHEDULE = "/schedule/addschedule";

//get all schedule
export const API_GET_ALL_SCHEDULE = "/schedule/getallschedule";

//get schedule details
export const API_GET_SCHEDULE_DETAILS = "/schedule/getscheduledetails";

//update schedule
export const API_UPDATE_SCHEDULE = "/schedule/updateschedule";

//delete schedule
export const API_DELETE_SCHEDULE = "/schedule/deleteschedule";

//get all educator with schedule
export const GET_ALL_EDUCATOR = "/schedule/geteducatorschedule";

//get educator availibility
export const API_CHECK_EDUCATOR_AVAILIBILITY =
  "/schedule/checkeducatoravailability";

// get all educators by date and shift
export const API_GET_EDUCATORS_BY_EVENT_AGENDA = "/schedule/educatorbyagenda";

/*-------- schedule url end --------*/

/*-------- holiday schedule url start --------*/
//add new schedule
export const API_ADD_NEW_HOLIDAY_SCHEDULE =
  "/holidayschedule/addholidayschedule";

//get all schedule
export const API_GET_ALL_HOLIDAY_SCHEDULE =
  "/holidayschedule/getallholidayschedule";

//update schedule
export const API_UPDATE_HOLIDAY_SCHEDULE =
  "/holidayschedule/updateholidayschedule";

//delete schedule
export const API_DELETE_HOLIDAY_SCHEDULE =
  "/holidayschedule/deleteholidayschedule";

//delete schedule
export const API_ISHOLIDAY_SCHEDULE = "/holidayschedule/isholiday";

/*-------- holiday schedule url end --------*/

/*--------- question type url start ---------*/

//get all question type
export const API_GET_ALL_QUESTION_TYPE = "/questiontype/getallquestiontype";
/*--------- question type url end ---------*/

/*--------- question url start ---------*/
//add question
export const API_CREATE_NEW_QUESTION = "/question/creatquestion";

//get all question
export const API_GET_ALL_QUESTION = "/question/getallquestions";

//update question
export const API_UPDATE_QUESTION = "/question/updatequestion";

//delete question
export const API_DELETE_QUESTION = "/question/deletequestion";
/*--------- question url end ---------*/

/*--------- survey url start ---------*/
//create survey
export const API_CREATE_SURVEY = "/survey/createsurvey";

//get all survey
export const API_GET_ALL_SURVEY = "/survey/getallsurvey";

//get survey details
export const API_GET_SURVEY_DETAILS = "/survey/getsurveydetailscode";

//update survey
export const API_UPDATE_SURVEY = "/survey/updatesurvey";

//delete survey
export const API_DELETE_SURVEY = "/survey/deletesurvey";

/*--------- survey url end ---------*/

/*-------- mail template start --------*/
//create
export const API_ADD_TEMPLATE = "/template/create";

//get all
export const API_GET_ALL_TEMPLATE = "/template/getall";

//get details
export const API_GET_TEMPLATE_DETAILS = "/template/getmailtemplatedetails";

//update
export const API_UPDATE_TEMPLATE = "/template/update";

//delete
export const API_DELETE_TEMPLATE = "/template/delete";

//add remove tag
export const API_ADD_REMOVE_TAG_TEMPLATE = "/template/addtagtotemplate";

/*-------- mail template end --------*/

/*-------- mail start --------*/
//create
export const API_ADD_MESSAGE = "/message/savemail";

//get all
export const API_GET_ALL_MESSAGE = "/message/getallmails";

//get all reserve mail
export const API_GET_ALL_RESERVE_MESSAGE = "/message/getallreservemail";

//update mail
export const API_UPDATE_MAIL = "/message/updatemail";

//delete
export const API_DELETE_MESSAGE = "/message/deletemail";

//add tag to CONVERSATION
export const API_ADD_TAG_TO_CONVERSATION = "/message/addtagtomessage";

//mail by template
export const API_SEND_MESSAGE_BY_TEMPLATE = "/message/savemailbytemplate";

export const API_MESSAGE_APPROVAL = "/message/updateapproval";

/*-------- mail end --------*/

/*-------- surveyfeedback url start ---------*/
//save survey feedback with answer
export const API_GIVE_SURVEY_FEEDBACK_BY_CLIENT =
  "/surveyfeedback/surveyfeedbackanswer";

//get all surveyfeedback
export const API_GET_ALL_SURVEY_FEEDBACK =
  "/surveyfeedback/getallsurveyfeedback";

//get a surveyfeedback
export const API_GET_SURVEY_FEEDBACK = "/surveyfeedback/surveyfeedbackdetails";

//get client survey feedback details
export const API_GET_CLIENT_SURVEY_FEEDBACK_DETAILS =
  "/surveyfeedback/getsurveyfeedbackdetailsclient";

//update survey
export const API_UPDATE_SURVEY_FEEDBACK =
  "/surveyfeedback/updatesurveyfeedback";

/*-------- surveyfeedback url end ---------*/

/*---------url for event membership start ----------*/
//save membership
export const API_SAVE_EVENT_MEMBERSHIP =
  "/eventmembership/saveupdatemembership";

/*---------url for event membership end ----------*/

/*-------- url for cost start --------*/
//create cost
export const API_CREATE_COST = "/cost/addreservecost";

//get all cost
export const API_GET_ALL_COST = "/cost/getreservecost";

//update cost
export const API_UPDATE_COST = "/cost/updatereservecost";

//delete cost
export const API_DELETE_COST = "/cost/deletereservecost";

/*-------- url for cost end --------*/

/*-------- url for sales order start --------*/
//get budget status options
export const API_GET_BUDGET_STATUS_LIST = "/budgetstatus/getallbudgetstatus";

//create sales order
export const API_CREATE_SALES_ORDER = "/salesorder/createsalesorder";

//budgettosalesorder
export const API_BUDGET_TO_SALES_ORDER = "/salesorder/budgettosalesorder";

//get all sales order
export const API_GET_ALL_SALES_ORDER = "/salesorder/getallsalesorders";

//get sales order by client
export const API_GET_SALES_ORDER_BY_CLIENT =
  "/salesorder/getsalesorderbyclient";

//get sales order details
export const API_GET_SALES_ORDER_DETAILS =
  "/salesorder/getallsalesorderdetails";

//get sales order details
export const API_GET_SALES_ORDER_RESERVE_LIST =
  "/salesorder/getreservelistbysearch";

//get reserve sales order details
export const API_GET_SALES_ORDER_RESERVE_DETAILS =
  "/salesorder/getreservesalesdetails";

//update sales order
export const API_UPDATE_SALES_ORDER = "/salesorder/updatesalesorder";

//update sales order item
export const API_UPDATE_SALES_ORDER_ITEM = "/salesorder/updatesalesorderitem";

//add more sales order item
export const API_ADD_MORE_SALES_ORDER_ITEM =
  "/salesorder/addmoresalesorderitem";

//add more sales order item on prev budget
export const API_ADD_MORE_SALES_ORDER_ITEM_PREV_BUDGET =
  "/salesorder/addmoreitemprevbudget";

//delete sales order
export const API_DELETE_SALES_ORDER = "/salesorder/deletesalesorder";

//delete sales order item
export const API_DELETE_SALES_ORDER_ITEM = "/salesorder/deletesalesorderitem";

//create note
export const API_CREATE_NOTE = "/salesorder/createnote";

//get all note
export const API_GET_ALL_NOTE = "/salesorder/getallnotes";

//sync to billage
export const API_SYNC_BILLAGE = "/salesorder/addinbillage";

/*-------- url for sales order end --------*/

/*-------- url for concept start --------*/
//create sales order
export const API_ADD_NEW_CONCEPT = "/concept/createconcept";

//get all sales order
export const API_GET_ALL_CONCEPT = "/concept/getallconcepts";

//update sales order
export const API_UPDATE_CONCEPT = "/concept/updateconcept";

//delete sales order
export const API_DELETE_CONCEPT = "/concept/deleteconcept";

/*-------- url for concept order end --------*/

/*-------- schedulematerialkit url start --------*/
//add new schedule
export const API_ADD_NEW_SCHEDULE_MATERIALKIT =
  "/schedulematerialkit/addschedulematerialkit";

//get all schedule
export const API_GET_ALL_SCHEDULE_MATERIALKIT =
  "/schedulematerialkit/getallschedulematerialkit";

//update schedule
export const API_UPDATE_SCHEDULE_MATERIALKIT =
  "/schedulematerialkit/updateschedulematerialkit";

//delete schedule
export const API_DELETE_SCHEDULE_MATERIALKIT =
  "/schedulematerialkit/deleteschedulematerialkit";

/*-------- schedulematerialkit url end --------*/

/*-------- reserve Filter start --------*/
//create filter
export const API_ADD_NEW_FILTER = "/filter/createfilter";

//get all filters
export const API_GET_ALL_FILTER = "/filter/getfilters";

//get default filter
export const API_GET_DEFAULT_FILTER = "/filter/getdefaultfilter";

//update filter
export const API_UPDATE_FILTER = "/filter/updatefilter";

//delete filter
export const API_DELETE_FILTER = "/filter/deletefilter";

/*-------- reserve Filter end --------*/

/*------- agenda url start --------*/
export const API_GET_ALL_AGENDA = "/agenda/getallagenda";

/*------- agenda url end --------*/

/*-------- rolepriviledge url start --------*/
//create rolepriviledge
export const API_ADD_ROLE_PRIVILEDGE = "/rolepriviledge/createrolepriviledge";

//get all rolepriviledge
export const API_GET_ALL_ROLE_PRIVILEDGE =
  "/rolepriviledge/getallrolepriviledges";

//get rolepriviledge details
export const API_GET_ROLE_PRIVILEDGE_DETAILS =
  "/rolepriviledge/getrolepriviledgedetails";

//get rolepriviledge details
export const API_GET_ROLE_PRIVILEDGE_DETAILS_BY_ROLEID =
  "/rolepriviledge/getrolepriviledgedetailsbyroleid";

//update rolepriviledge
export const API_UPDATE_ROLE_PRIVILEDGE =
  "/rolepriviledge/updaterolepriviledge";

//delete rolepriviledge
export const API_DELETE_ROLE_PRIVILEDGE =
  "/rolepriviledge/deleterolepriviledge";
/*-------- rolepriviledge url end --------*/

/*==== project url start ====*/
//add proejct
export const API_ADD_NEW_PROJECT = "/project/addproject";

//get all project
export const API_GET_ALL_PROJECTS = "/project/getallprojects";

//get project details
export const API_GET_PROJECT_DETAILS = "/project/getprojectdetails";

//update proejct
export const API_UPDATE_PROJECT = "/project/updateproject";

//add remove tag project
export const API_ADD_REMOVE_TAG_PROJECT = "/project/addtagproject";

//add task
export const API_ADD_NEW_PROJECT_TASK = "/project/addtask";

//get all task
export const API_GET_ALL_TASKS = "/project/getalltasks";

//get task details
export const API_GET_TASK_DETAILS = "/project/gettaskdetails";

//update task
export const API_UPDATE_PROJECT_TASK = "/project/updatetask";

//add tag task
export const API_ADD_TAG_TASK = "/project/addtagtask";

//add log
export const API_ADD_PROJECT_TASK_LOG = "/project/addtasklog";

//get all logs
export const API_GET_PROJECT_TASK_LOGS = "/project/getallprojectlogs";

//add label to task
export const API_ADD_TAG_TASK_LOG = "/project/addremovetagtasklog";

//get log details
export const API_GET_LOG_DETAILS = "/project/getlogdetails";

//update log
export const API_UPDATE_TASK_LOG = "/project/updatetasklog";

/*==== project url end ====*/

/*==== employee log url start ====*/
//add new log
export const API_SAVE_EMPLOYEE_LOG = "/employeelog/saveemployeelog";

//get log details
export const API_GET_EMPLOYEE_LOG_DETAILS =
  "/employeelog/getemployeelogdetails";

//update log
export const API_UPDATE_EMPLOYEE_LOG = "/employeelog/updateemployeelog";

//get all employee logs
export const API_GET_ALL_EMPLOYEE_LOGS = "/employeelog/getallemployeelogs";

/*==== employee log url end ====*/

/*==== action logs ====*/
export const API_GET_ACTION_LOGS = "/actionlogs/getlogs";

export const API_POST_SMTP = "/message/testsmtp";
