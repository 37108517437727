/* eslint-disable */
import { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";

/* Importing react select */
import Select from "react-select";

//import translation function for language translation
import { useTranslation } from "react-i18next";

//import options from json file
import ReserveStatusOptions from "data/Prod/ReserveStatus.json";
import typeOfActivity from "data/Prod/ActivityType.json";
import levelOption from "data/Prod/Level.json";
import batchLotOption from "data/Prod/Batch.json";
import specificAudience from "data/Prod/SpecialAudience.json";
import SpecialNeeds from "data/Prod/SpecialNeeds.json";
import Shifts from "data/Prod/Shifts.json";

/*import url and gateway methods */
import { postData, getData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

//import context
import { GlobalProvider } from "context/GlobalContext";

//import get current time and set by defualt
import { getCurrentTime, getEndTime } from "helpers/TimeCalculation";

//import reserve status options

const ReservationSavePopup = () => {
  const token = localStorage.getItem("token");
  const { t, i18n } = useTranslation(); //for translation

  const {
    setReloadReservationList,
    editReserveData,
    setEditReserveData,
    resetReserveSaveForm,
    setResetReserveSaveForm,
    setSuccess,
    setNotificationString,
    setError,
    copyReserveData,
    setCopyReserveData,
    addMoreConsecutiveReserveData,
    setaddMoreConsecutiveReserveData,
    setUserFormRole,
    reloadReserveFormOptions,
    setReloadReserveFormOptions,
    newAddedClientResult,
    setNewAddedClientResult,
    newAddedBeneficiaryResult,
    setNewAddedBeneficiaryResult,
    newAddedObserverResult,
    setNewAddedObserverResult,
    newAddedParticipantResult,
    setNewAddedParticipantResult,
    newAddedResponsibleResult,
    setNewAddedResponsibleResult,
    setAllowSelectEducator,
    setChangeReserveEducatorData,
  } = useContext(GlobalProvider);

  const dateOfToday = new Date().toISOString().split("T")[0];

  const [reserveId, setReserveId] = useState(null);
  const [ambReserveId, setAmbReserveId] = useState(null);
  const [courseList, setCourseList] = useState([]); //course option
  const [activityList, setActivityList] = useState([]); //materila kit option
  const [labelList, setLabelList] = useState([]); //label option
  const [educatorList, setEducatorList] = useState([]); //educator option
  const [clientList, setClientList] = useState([]); //client option
  const [observerList, setObserverList] = useState([]); //observer option
  const [benificiaryList, setBenificiaryList] = useState([]); //benificiary option
  const [participantList, setParticipantList] = useState([]); //participant option
  const [responsibleList, setResponsibleList] = useState([]); //responsible option
  const [reserveTemplateList, setReserveTemplateList] = useState([]); //template option
  const [activityHours, setActivityHours] = useState("");

  /* hooks for input fields starts */
  const [startDate, setStartDate] = useState(dateOfToday);
  // const [endDate, setEndDate] = useState("");
  const [startTime, setstartTime] = useState(getCurrentTime());
  const [endTime, setEndTime] = useState(getCurrentTime());
  const [noOfEducators, setNoOfEducators] = useState("1");
  const [noOfParticipants, setNoOfParticipants] = useState("");
  const [noParticipantsReal, setNoParticipantsReal] = useState("");
  const [billingUnit, setBillingUnit] = useState("");
  const [tutor, setTutor] = useState("");
  const [grade, setGrade] = useState("");
  const [noOfCompanions, setNoOfCompanions] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [location, setLocation] = useState("");
  const [city, setCity] = useState("");
  const [town, setTown] = useState("");
  const [stateName, setStateName] = useState("");
  const [country, setCountry] = useState("");
  const [responsileName, setResponsileName] = useState("");
  const [responsileEmail, setResponsileEmail] = useState("");
  const [responsilePhone, setResponsilePhone] = useState("");
  const [comments, setComments] = useState("");
  const [internalComments, setInternalComments] = useState("");
  const [isTemplate, setIsTemplate] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [isConsecutive, setIsConsecutive] = useState(false);
  const [consecutiveReserveId, setConsecutiveReserveId] = useState(null);
  const [shift, setShift] = useState("");
  const [associatedConsecutiveList, setAssociatedConsecutiveList] = useState(
    []
  );
  const [isRequiredFieldFillPending, setIsRequiredFieldFillPending] =
    useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [holidaySelected, setHolidaySelected] = useState(false);
  const [kitAvailabilityMessages, setKitAvailabilityMessages] = useState([]);

  /* hooks for input fields ends */

  const [changeSelect, setChangeSelect] = useState({
    selectedTemplate: null,
    selectedStatus: null,
    selectedCourses: null,
    selectedActivity: null,
    selectedActivityType: null,
    selectedEducators: null,
    selectedParticipants: null,
    selectedScope: null,
    selectedSpecialNeeds: null,
    selectedBatch: null,
    selectedClient: null,
    selectedObserver: null,
    selectedAudience: null,
    selectedBenificiary: null,
    selectLabels: null,
    selectedResponsible: null,
  });

  const [educatorVailableMessage, setEducatorVailableMessage] = useState([]);

  /* hooks to post select data start */
  const [statusData, setStatusData] = useState(null);
  const [courseData, setCourseData] = useState(null);
  const [activityData, setActivityData] = useState(null);
  const [activityCodeId, setActivityCodeId] = useState(null);
  const [activityTypeData, setActivityTypeData] = useState(null);
  const [educatorData, setEducatorData] = useState([]);
  const [levelData, setLevelData] = useState([]);
  const [specialNeedsData, setSpecialNeedsData] = useState([]);
  const [batchData, setBatchData] = useState(null);
  const [specificAudienceData, setSpecificAudienceData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [observerData, setObserverData] = useState([]);
  const [benificiaryData, setBenificiaryData] = useState([]);
  const [participantsData, setParticipantsData] = useState([]);
  const [responsibleData, setResponsibleData] = useState([]);
  const [labelData, setLabelData] = useState([]);
  /* hooks to post select data end */

  /* Hook for validation start */
  const [validation, setValidation] = useState({
    templateWarning: false,
    startDateWarning: false,
    startTimeWarning: false,
    endTimeWarning: false,
    noOfEducatorsWarning: false,
    noOfParticipantsWarning: false,
    noParticipantsRealWarning: false,
    billingUnitWarning: false,
    noOfCompanionsWarning: false,
    locationWarning: false,
    zipcodewarning: false,
    cityWarning: false,
    stateWarning: false,
    countryWarning: false,
    responsileWarning: false,
    commentsWarning: false,
    internalCommentWarning: false,
    statusWarning: false,
    courseWarning: false,
    activityWarning: false,
    activityTypeWarning: false,
    educatorWarning: false,
    levelWarning: false,
    specialNeedsWarning: false,
    batchWarning: false,
    specificAudienceWarning: false,
    clientWarning: false,
    observerWarning: false,
    benificiaryWarning: false,
    participantsWarning: false,
    timeCompareWarning: false,
  });
  /* Hook for validation end */

  //function for get all reserve as select
  const getAllReserveTemplate = async () => {
    // console.log('inside GET Reserve Template >>>>>>>>');
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_RESERVE_EVENTS +
        `?token=${token}&isselect=${true}&istemplate=${true}`;

      // console.log("Get all reserver template url========>", requestUrl);

      const response = await getData(requestUrl);

      // console.log("Get all reserver template response========>", response);

      if (response.status) {
        setReserveTemplateList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function get reserve details
  const getReserveDetails = async (reserveId, iscopy = false) => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_RESERVE_DETAILS +
        `?reserveid=${reserveId}`;

      const response = await getData(requestUrl);

      console.log("reserve details response =======>", response);

      if (response.status) {
        setValidation({
          startDateWarning: false,
          startTimeWarning: false,
          endTimeWarning: false,
          noOfEducatorsWarning: false,
          noOfParticipantsWarning: false,
          noParticipantsRealWarning: false,
          billingUnitWarning: false,
          noOfCompanionsWarning: false,
          locationWarning: false,
          zipcodewarning: false,
          responsileNameWarning: false,
          responsileWarning: false,
          responsilePhoneWarning: false,
          commentsWarning: false,
          statusWarning: false,
          courseWarning: false,
          activityWarning: false,
          activityTypeWarning: false,
          educatorWarning: false,
          levelWarning: false,
          specialNeedsWarning: false,
          batchWarning: false,
          specificAudienceWarning: false,
          clientWarning: false,
          observerWarning: false,
          benificiaryWarning: false,
          participantsWarning: false,
        });

        setReserveId(null);
        setAmbReserveId(null);
        setConsecutiveReserveId(null);

        if (editReserveData) {
          setReserveId(response?.data?.reserveid);
          setAmbReserveId(response?.data?.ambreservationid);
          //assign consecutive reserve id
          setConsecutiveReserveId(response?.data?.consecutivereserveid?._id);

          /*========= assign status start =========*/
          for (let reserveStatus of ReserveStatusOptions) {
            if (reserveStatus.value == response.data.reservestatus) {
              statusSelectionHandler(reserveStatus);
            }
          }
          /*========= assign status end =========*/
        } else {
          for (let reserveStatus of ReserveStatusOptions) {
            if (reserveStatus.value == "3") {
              statusSelectionHandler(reserveStatus);
            }
          }
        }

        //assing is consecutive
        response.data?.isconsecutivereserve &&
          setIsConsecutive(response.data.isconsecutivereserve);

        /*========= assign course start =========*/
        if (response.data.course) {
          let courseData = {
            label: response.data.course.name,
            value: response.data.course._id,
          };
          courseSelectionHandler(courseData);
        }
        /*========= assign course end =========*/

        /*========= assign activity with name and code start =========*/
        if (response.data.activitycode) {
          setActivityCodeId(response.data.activitycode._id);

          let activityData = {
            label:
              response.data.activitycode.code +
              " : " +
              response.data.activitycode.name,
            value: response.data.activitycode._id,
          };
          activitySelectionHandler(activityData);
          setActivityHours(response?.data?.activitycode?.duration);
        }
        /*========= assign activity with name and code end =========*/

        /*========= assign activity type start =========*/
        if (response.data.activitytype) {
          let activityData = {
            label: response.data.activitytype,
            value: response.data.activitytype,
          };
          selectActivityTypeHandler(activityData);
        }
        /*========= assign activity type end =========*/

        /*========= no of educator start =========*/
        response.data?.educatorcount &&
          setNoOfEducators(response.data.educatorcount);
        /*========= no of educator end =========*/

        /*========= no of participants start =========*/
        response.data?.participantcount &&
          setNoOfParticipants(response.data.participantcount);
        /*========= no of participants end =========*/

        /*========= no of participants real start =========*/
        response.data?.realparticipantcount &&
          setNoParticipantsReal(response.data.realparticipantcount);
        /*========= no of participants real end =========*/

        /*========= no of billing unit start =========*/
        response.data?.billingunits &&
          setBillingUnit(response.data.billingunits);
        /*========= no of billing unit end =========*/

        /*========= tutor start =========*/
        response.data?.tutor && setTutor(response.data.tutor);
        /*========= tutor end =========*/

        /*========= grade start =========*/
        response.data?.grade && setGrade(response.data.grade);
        /*========= grade end =========*/

        /*========= assign istemplate start =========*/
        response.data?.istemplate && setIsTemplate(response.data.istemplate);
        /*========= assign istemplate end =========*/

        /*========= assign templatename start =========*/
        response.data?.templatename &&
          setTemplateName(response.data.templatename);
        /*========= assign templatename end =========*/

        /*========= assign levels start =========*/
        if (response.data.levels) {
          let levelValue = response.data.levels.map((level) => ({
            label: level,
            value: level,
          }));
          levelSelectionHandler(levelValue);
        }
        /*========= assign levels end =========*/

        /*========= specific need start =========*/
        if (response.data.specificneeds) {
          let needValue = response.data.specificneeds.map((needValue) => ({
            label: needValue,
            value: needValue,
          }));
          selectSpecialNeedsHandler(needValue);
        }
        /*========= specific need end =========*/

        /*========= batch/lot start =========*/
        if (response.data.batch) {
          batchSelectionHandler({
            label: response.data.batch,
            value: response.data.batch,
          });
        }
        /*========= batch/lot end =========*/

        /*========= specific audience start =========*/
        if (response.data.specificaudience) {
          let audienceValue = response.data.specificaudience.map(
            (audience) => ({
              label: audience,
              value: audience,
            })
          );
          specificAudienceHandler(audienceValue);
        }
        /*========= specific audience end =========*/

        /*========= no of companion start =========*/
        response.data?.companioncount &&
          setNoOfCompanions(response.data.companioncount);
        /*========= no of companion end =========*/

        /*========= assign responsiblename start =========*/
        response.data?.responsiblename &&
          setResponsileName(response.data.responsiblename);
        /*========= assign responsiblename end =========*/

        /*========= assign responsiblemail start =========*/
        response.data?.responsiblemail &&
          setResponsileEmail(response.data.responsiblemail);
        /*========= assign responsiblemail end =========*/

        /*========= assign responsiblephone start =========*/
        response.data?.responsiblephone &&
          setResponsilePhone(response.data.responsiblephone);
        /*========= assign responsiblephone end =========*/

        /*========= assign comments start =========*/
        response.data?.comments && setComments(response.data.comments);
        /*========= assign comments end =========*/

        /*========= assign internalcomments start =========*/
        response.data?.internalcomment &&
          setInternalComments(response.data.internalcomment);
        /*========= assign internalcomments end =========*/

        if (addMoreConsecutiveReserveData) {
          //assign consecutive reserve id
          setConsecutiveReserveId(response?.data?.consecutivereserveid?._id);

          /*========= assign start time and end time start =========*/
          response.data?.endtime && setstartTime(response.data?.endtime);

          const getEndTimeValue = getEndTime(
            response?.data?.activitycode?.duration,
            response.data?.endtime
          );

          setEndTime(getEndTimeValue);
        } else {
          response.data?.starttime && setstartTime(response.data?.starttime);
          response.data?.endtime && setEndTime(response.data?.endtime);
          /*========= assign start time and end time end =========*/
        }

        /*========= assign date start =========*/
        response.data?.startdate && setStartDate(response.data.startdate);
        /*========= assign date end =========*/

        /*========= assign educator start =========*/
        console;
        if (response.data.educators && iscopy == false) {
          let educatorValue = response.data.educators.map((educator) => ({
            label: educator.name
              ? educator?.name + " " + educator?.surname
              : educator.email,
            value: educator._id,
          }));

          educatorsSelectionHandler(
            educatorValue,
            response?.data?.educatorcount
          );
        }
        /*========= assign educator end =========*/

        /*========= assign Beneficiary start =========*/
        if (response.data.beneficiaries) {
          let beneficiaryValue = response.data.beneficiaries.map(
            (beneficiary) => ({
              label: beneficiary?.name
                ? beneficiary?.name +
                  " " +
                  (beneficiary.surname ? beneficiary.surname : "")
                : beneficiary.email,
              value: beneficiary._id,
            })
          );
          selectBenificiaryHandler(beneficiaryValue);
        }
        /*========= assign Beneficiary end =========*/

        /*========= assign client start =========*/
        if (response.data.clients) {
          let clientValue = response.data.clients.map((client) => ({
            label: client.name
              ? client.name + " " + (client.surname ? client.surname : "")
              : client.email,
            value: client._id,
          }));
          selectClientHandler(clientValue);
        }
        /*========= assign client end =========*/

        /*========= assign observer start =========*/
        if (response.data.observers) {
          let observerValue = response.data.observers.map((observer) => ({
            label: observer.name
              ? observer.name + " " + (observer.surname ? observer.surname : "")
              : observer.email,
            value: observer._id,
          }));
          selectObserverHandler(observerValue);
        }
        /*========= assign observer end =========*/

        /*========= assign Participants start =========*/
        if (response.data.participants) {
          let participantsValue = response.data.participants.map(
            (participant) => ({
              label: participant.name
                ? participant.name +
                  " " +
                  (participant.surname ? participant.surname : "")
                : participant.email,
              value: participant._id,
            })
          );
          selectParticipantsHandler(
            participantsValue,
            response?.data?.participantcount
          );
        } /*========= assign Participants end =========*/

        /*========= assign Responsible start =========*/
        if (response.data.responsibles) {
          let responsibleValue = response.data.responsibles.map(
            (responsible) => ({
              label: responsible.name
                ? responsible.name +
                  " " +
                  (responsible.surname ? responsible.surname : "")
                : responsible.email,
              value: responsible._id,
            })
          );
          responsibleSelectionHandler(responsibleValue);
        } /*========= assign Responsible end =========*/

        /*========= assign Location start =========*/
        response.data?.location && setLocation(response.data.location);
        /*========= assign Location end =========*/

        /*========= assign town start =========*/
        response.data?.town && setTown(response.data.town);
        /*========= assign town end =========*/

        /*========= assign zipcode start =========*/
        response.data?.zipcode && setZipCode(response.data.zipcode);
        /*========= assign zipcode end =========*/

        /*========= assign city start =========*/
        response.data?.city && setCity(response.data.city);
        /*========= assign city end =========*/

        /*========= assign state start =========*/
        response.data?.state && setStateName(response.data.state);
        /*========= assign state end =========*/

        /*========= assign country start =========*/
        response.data?.country && setCountry(response.data.country);
        /*========= assign country end =========*/

        /*========= assign Labels / Tags start =========*/
        if (response.data.tags) {
          let tagValue = response.data.tags.map((tag) => ({
            label: tag.title,
            value: tag._id,
          }));
          labelSelectionHandler(tagValue);
        } /*========= assign Labels / Tags end =========*/

        //assign consecutive reserve list
        if (addMoreConsecutiveReserveData || editReserveData) {
          const consecutivereservelist =
            response.data?.consecutivereservelist ?? [];
          setAssociatedConsecutiveList(consecutivereservelist);
        } else {
          setAssociatedConsecutiveList([]);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get course list
  const getAllCourseList = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_COURSE +
        `?token=${token}&isselect=${true}`;

      const response = await getData(requestUrl);

      if (response.status) {
        setCourseList(response.data);
        courseSelectionHandler(response.data[response.data.length - 1]);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for get all activity
  const getAllActivityList = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_ACTIVITY +
        `?token=${token}&isselect=${true}`;

      const response = await getData(requestUrl);

      if (response.status) {
        setActivityList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for get all educators
  const getAllEducators = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_USER +
        `?token=${token}&isselect=${true}&slug=educator`;

      // console.log("url of educator list------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("list educators basic -->", response);

      if (response.status) {
        setEducatorList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for get all user list
  const getAllEducatorListByAvailability = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.GET_ALL_EDUCATOR +
        `?token=${token}` +
        `&date=${startDate}` +
        `&shift=${shift}` +
        `&starttime=${startTime}` +
        `&endtime=${endTime}` +
        `&activitycode=${activityData}`;

      console.log("req url for educator -->", requestUrl);

      const response = await getData(requestUrl);

      console.log("list of educatros -->", response);

      if (response.status) {
        setEducatorList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const getOptionLabel = (option) => (
    <div>
      <b>{option.label}</b> {" || "}
      <span>
        {t("Availability")} :{" "}
        {option.availability ? (
          <span className="logged-in">●</span>
        ) : (
          <span className="logged-out">●</span>
        )}
      </span>
      {" || "}
      <span>{t("Rank")} : -- </span>
      {" || "}
      <span>
        {t("Certificate")} :{" "}
        {option.certificate == "Yes" ? (
          <span className="logged-in">●</span>
        ) : (
          <span className="logged-out">●</span>
        )}
      </span>
    </div>
  );

  useEffect(() => {
    if (startDate && startTime && endTime && activityData) {
      getAllEducatorListByAvailability();
    }
  }, [startDate, startTime, endTime, activityData]);

  //function for get all clients
  const getAllClient = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_USER +
        `?token=${token}&isselect=${true}&slug=client`;

      const response = await getData(requestUrl);

      //console.log('list client -->', response);

      if (response.status) {
        setClientList(response.data);
        setBenificiaryList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for get all beneficiary
  // const getAllBenificiary = async () => {
  //   try {
  //     let requestUrl =
  //       url.API_BASEURL +
  //       url.API_GET_ALL_USER +
  //       `?token=${token}&isselect=${true}&slug=beneficiary`;

  //     // console.log("url of beneficiary list------>", requestUrl);

  //     const response = await getData(requestUrl);

  //     // console.log("list beneficiary -->", response);

  //     if (response.status) {
  //       setBenificiaryList(response.data);
  //     }
  //   } catch (error) {
  //     console.error(error.message);
  //   }
  // };

  //function for get all observer
  const getAllObserver = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_USER +
        `?token=${token}&isselect=${true}`;

      // console.log("url of observer list------>", requestUrl);

      const response = await getData(requestUrl);

      // console.log("list observer -->", response);

      if (response.status) {
        setObserverList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for get all participants
  const getAllParticipantsAndResponsible = async (clientId) => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_USER_BY_COMPANY +
        `?token=${token}&clientid=${clientId}`;

      // console.log("url of participant list------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("list participant -->", response);

      if (response.status) {
        setParticipantList(response.data);
        setResponsibleList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for get all label list
  const getAllLabels = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_TAG +
        `?token=${token}&isselect=${true}`;

      const response = await getData(requestUrl);

      if (response.status) {
        setLabelList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for select status
  const statusSelectionHandler = (val) => {
    setChangeSelect((prevState) => ({
      ...prevState,
      selectedStatus: val,
    }));
    setStatusData(val.value);
  };

  //function for course selection
  const courseSelectionHandler = (val) => {
    setChangeSelect((prevState) => ({
      ...prevState,
      selectedCourses: val,
    }));
    setCourseData(val.value);
  };

  //function for activity selection
  const activitySelectionHandler = (val) => {
    setChangeSelect((prevState) => ({
      ...prevState,
      selectedActivity: val,
    }));
    setActivityData(val.value);

    if (val.duration) {
      const getEndTimeValue = getEndTime(val.duration, startTime);

      setEndTime(getEndTimeValue);

      setActivityHours(val.duration);
    }

    if (val.educatorcount) {
      setNoOfEducators(val.educatorcount);
    }
  };

  /* function to select activity type */
  const selectActivityTypeHandler = (val) => {
    setChangeSelect((prevState) => ({
      ...prevState,
      selectedActivityType: val,
    }));
    setActivityTypeData(val.value);
  };

  /* function to select educators */
  const educatorsSelectionHandler = (val, educatorCount) => {
    // let offCanvasPopupEducator = document.querySelector("#selectEducatorPopup");
    // let offCanvasEducator = bootstrap.Offcanvas.getInstance(offCanvasPopupEducator);
    // offCanvasEducator.show();

    const noOfEducatorsInt = parseInt(educatorCount);

    if (val.length <= noOfEducatorsInt) {
      setChangeSelect((prevState) => ({
        ...prevState,
        selectedEducators: val,
      }));

      setEducatorData(val.map((educator) => educator.value));
    }
  };

  const changeEducatorHandler = () => {
    const reserveData = {
      startdate: startDate,
      starttime: startTime,
      endtime: endTime,
      shift: shift,
      reserveId: reserveId,
      prevEducatore: null,
      activitycodename: "",
      reserveid: reserveId,
      activitycode: activityCodeId,
    };

    setChangeReserveEducatorData(reserveData);
  };

  //function for check if selected date is holiday --------------------------
  const checkisHoliday = async (selectedDate) => {
    if (selectedDate) {
      let requestUrl =
        url.API_BASEURL +
        url.API_ISHOLIDAY_SCHEDULE +
        `?token=${token}&date=${selectedDate}`;

      // console.log(requestUrl);

      const response = await getData(requestUrl);
      // console.log(response);

      if (response.status) {
        setHolidaySelected(response.isholiday);
      }
    }
  };

  //function for check material kit availibility -------------------------------
  const checkMaterialKitAvailable = async (selectedDate) => {
    if (selectedDate) {
      let requestUrl =
        url.API_BASEURL +
        url.API_KIT_ISAVAILABLE +
        `?token=${token}&date=${selectedDate}&activity=${activityData}`;

      console.log("KIT request", requestUrl);

      const response = await getData(requestUrl);
      console.log("KIT response", response);

      if (response.status) {
        setKitAvailabilityMessages(response.data);
      }
    }
  };

  //function for check edcuator availibility
  const checkEducatorAvailibility = async () => {
    if (educatorData.length > 0) {
      let educatorResponse = [];
      for (let educatorId of educatorData) {
        let requestUrl =
          url.API_BASEURL +
          url.API_CHECK_EDUCATOR_AVAILIBILITY +
          `?token=${token}&educatorid=${educatorId}&date=${startDate}&starttime=${startTime}&endtime=${endTime}`;
        const response = await getData(requestUrl);
        // console.log(response);
        if (response.status) {
          educatorResponse.push(response.data);
        }
      }
      setEducatorVailableMessage(educatorResponse);
    } else {
      setEducatorVailableMessage([]);
    }
  };

  /* function to select scope */
  const levelSelectionHandler = (val) => {
    setChangeSelect((prevState) => ({
      ...prevState,
      selectedScope: val,
    }));
    setLevelData(val.map((scope) => scope.value));
  };

  /* function to select batch  */
  const batchSelectionHandler = (val) => {
    setChangeSelect((prevState) => ({
      ...prevState,
      selectedBatch: val,
    }));
    setBatchData(val.value);
  };

  /* function to select audience */
  const specificAudienceHandler = (val) => {
    setChangeSelect((prevState) => ({
      ...prevState,
      selectedAudience: val,
    }));
    setSpecificAudienceData(val.map((audience) => audience.value));
  };

  /* function to select client */
  const selectClientHandler = (val) => {
    setChangeSelect((prevState) => ({
      ...prevState,
      selectedClient: val,
    }));
    setClientData(val.map((client) => client.value));
  };

  /* function to select observer */
  const selectObserverHandler = (val) => {
    setChangeSelect((prevState) => ({
      ...prevState,
      selectedObserver: val,
    }));
    setObserverData(val.map((observer) => observer.value));
  };

  /* function to select benificiary */
  const selectBenificiaryHandler = (val) => {
    // console.log(val);
    if (val.length > 0) {
      setLocation(val[0].location);
      setZipCode(val[0].zipcode);
      setCity(val[0].city);
      setTown(val[0].city);
      setStateName(val[0].state);
      setCountry(val[0].country);
      setValidation((prevState) => ({
        ...prevState,
        zipcodewarning: false,
        locationWarning: false,
      }));
    }
    setChangeSelect((prevState) => ({
      ...prevState,
      selectedBenificiary: val,
    }));
    setBenificiaryData(val.map((benificiary) => benificiary.value));

    if (val && val.length > 0) {
      getAllParticipantsAndResponsible(val[0].value);
    } else {
      setParticipantList([]);
      setResponsibleList([]);
    }
  };

  /* function to select participants */
  const selectParticipantsHandler = (val, participantCount) => {
    const noOfParticipantsInt = parseInt(participantCount);

    if (val.length <= noOfParticipantsInt) {
      setChangeSelect((prevState) => ({
        ...prevState,
        selectedParticipants: val,
      }));
      setParticipantsData(val.map((participant) => participant.value));
    }
  };

  //function for select responsible
  const responsibleSelectionHandler = (val) => {
    setChangeSelect((prevState) => ({
      ...prevState,
      selectedResponsible: val,
    }));
    setResponsibleData(val.map((responsible) => responsible.value));
  };

  /* function to select special needs */
  const selectSpecialNeedsHandler = (val) => {
    setChangeSelect((prevState) => ({
      ...prevState,
      selectedSpecialNeeds: val,
    }));
    setSpecialNeedsData(val.map((specialNeed) => specialNeed.value));
  };

  //function for label selection
  const labelSelectionHandler = (val) => {
    setChangeSelect((prevState) => ({
      ...prevState,
      selectLabels: val,
    }));
    setLabelData(val.map((client) => client.value));
  };

  //function for get reserve details from selected template
  const reserveTemplateSelectionHandler = async (value) => {
    setChangeSelect((prevState) => ({ ...prevState, selectedTemplate: value }));
    getReserveDetails(value.value);
  };

  /* function to fetch location from zip code */
  const getLocationFromZip = async (e) => {
    e.preventDefault();

    const url = `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${zipCode}&address=ES&region=es&key=AIzaSyDt4dbtdHCHLj8grTzTVxpztvqUGDkvkaI`;

    if (zipCode !== "") {
      try {
        const response = await fetch(url);
        const data = await response.json();

        if (data.status === "OK") {
          const results = data.results;

          if (results.length > 0) {
            // console.log(`results`, JSON.stringify(results));

            results[0]?.address_components.map((item) => {
              // console.log(`item`, JSON.stringify(item));

              if (item.types[0] === "country") {
                setCountry(item?.long_name);
              }
              if (item.types[0] === "locality") {
                setCity(item?.long_name);
                setLocation(item?.long_name);
                setTown(item?.long_name);
              }
              if (item.types[0] === "administrative_area_level_1") {
                setStateName(item?.long_name);
              }
              // setLatitude(results[0]?.geometry?.location?.lat);
              // setLongitude(results[0]?.geometry?.location?.lng);

              // setMapCoordinate(
              //   `https://maps.google.com/maps?q=${results[0]?.geometry?.location?.lat},${results[0]?.geometry?.location?.lng}`
              // );
            });
          } else {
            console.log("No results found.");
          }
        } else {
          console.log("Request failed with status:", data.status);
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  };

  /* function for validation */
  const validateForm = () => {
    let isValid = true;

    if (isTemplate && templateName == "") {
      setValidation((prevState) => ({ ...prevState, templateWarning: true }));
      isValid = false;
    }

    if (activityData === null) {
      setValidation((prevState) => ({ ...prevState, activityWarning: true }));
      isValid = false;
    }

    if (startDate === "") {
      setValidation((prevState) => ({ ...prevState, startDateWarning: true }));
      isValid = false;
    }
    if (startTime === "") {
      setValidation((prevState) => ({ ...prevState, startTimeWarning: true }));
      isValid = false;
    }
    if (endTime === "") {
      setValidation((prevState) => ({ ...prevState, endTimeWarning: true }));
      isValid = false;
    }
    if (noOfEducators === "") {
      setValidation((prevState) => ({
        ...prevState,
        noOfEducatorsWarning: true,
      }));
      isValid = false;
    }
    // if (noOfParticipants === "") {
    //   setValidation((prevState) => ({
    //     ...prevState,
    //     noOfParticipantsWarning: true,
    //   }));
    //   isValid = false;
    // }
    // if (changeSelect.selectedEducators === null) {
    //   setValidation((prevState) => ({
    //     ...prevState,
    //     educatorWarning: true,
    //   }));
    //   isValid = false;
    // }
    // if (changeSelect.selectedBenificiary === null) {
    //   setValidation((prevState) => ({
    //     ...prevState,
    //     benificiaryWarning: true,
    //   }));
    //   isValid = false;
    // }
    if (changeSelect.selectedClient === null) {
      setValidation((prevState) => ({
        ...prevState,
        clientWarning: true,
      }));
      isValid = false;
    }
    // if (changeSelect.selectedObserver === null) {
    //   setValidation((prevState) => ({
    //     ...prevState,
    //     observerWarning: true,
    //   }));
    //   isValid = false;
    // }
    // if (changeSelect.selectedParticipants === null) {
    //   setValidation((prevState) => ({
    //     ...prevState,
    //     participantsWarning: true,
    //   }));
    //   isValid = false;
    // }
    if (zipCode === "") {
      setValidation((prevState) => ({
        ...prevState,
        zipcodewarning: true,
      }));
      isValid = false;
    }

    if (location === "") {
      setValidation((prevState) => ({ ...prevState, locationWarning: true }));
      isValid = false;
    }

    if (city === "") {
      setValidation((prevState) => ({ ...prevState, cityWarning: true }));
      isValid = false;
    }
    if (stateName === "") {
      setValidation((prevState) => ({ ...prevState, stateWarning: true }));
      isValid = false;
    }
    if (country === "") {
      setValidation((prevState) => ({ ...prevState, countryWarning: true }));
      isValid = false;
    }

    if (changeSelect.selectedResponsible == null) {
      setValidation((prevState) => ({
        ...prevState,
        responsileWarning: true,
      }));
      isValid = false;
    }

    if (startTime > endTime) {
      setValidation((prevState) => ({
        ...prevState,
        timeCompareWarning: true,
      }));
      isValid = false;
    }
    return isValid;
  };

  //function for checking the shift validation.If validate then shift will assign into api data
  const mapShiftData = () => {
    Shifts.map((shiftData) => {
      if (shiftData.start <= startTime && shiftData.end >= endTime) {
        setShift(shiftData.id);
      }
    });
  };

  /* function to save reservation */
  const saveReservationHandler = async () => {
    let reservationData = {
      parentid: editReserveData ? editReserveData._id : null,
      reserveid: reserveId,
      ambreservationid: ambReserveId,
      reservestatus: statusData,
      course: courseData,
      startdate: startDate,
      starttime: startTime,
      endtime: endTime,
      activitytype: activityTypeData,
      activitycode: activityData,
      educatorcount: noOfEducators,
      participantcount: noOfParticipants,
      realparticipantcount: noParticipantsReal,
      billingunits: billingUnit,
      grade,
      tutor,
      educators: educatorData,
      levels: levelData,
      specificneeds: specialNeedsData,
      batch: batchData,
      specificaudience: specificAudienceData,
      companioncount: noOfCompanions,
      clients: clientData,
      observers: observerData,
      beneficiaries: benificiaryData,
      participants: participantsData,
      location,
      zipcode: zipCode,
      city,
      town,
      state: stateName,
      country,
      responsiblename: responsileName,
      responsiblemail: responsileEmail,
      responsiblephone: responsilePhone,
      responsibles: responsibleData,
      comments,
      internalcomment: internalComments,
      tags: labelData,
      istemplate: isTemplate,
      templatename: templateName,
      shift: shift,
      isconsecutivereserve: isConsecutive,
      consecutivereserveid: consecutiveReserveId,
    };

    // console.log(`reservation Data ==========>>>`, reservationData);

    if (validateForm()) {
      setIsRequiredFieldFillPending(false);
      try {
        setIsSaving(true);
        let requestUrl =
          url.API_BASEURL + url.API_ADD_RESERVE_EVENT + `?token=${token}`;

        if (editReserveData) {
          requestUrl = requestUrl + `&previousid=${editReserveData._id}`;
        }

        const response = await postData(requestUrl, reservationData);

        console.log(`reservation save response ==========>>>`, response);

        if (response.status) {
          // if true returned, then close the offCanvas popup
          let offCanvasPopup = document.querySelector("#saveReservationPopup");
          let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
          offCanvas.hide();

          resetHandler();
          setReloadReservationList(true);
          setIsSaving(false);
          setNotificationString(
            t("Reservation saved successfully") +
              "  RESERVE ID - " +
              response.data.reserveid
          );
          setSuccess(true);

          if (response.reserveinweek == true) {
            console.log("inside reserve in week mail to beneficiary");

            const beneficiaryId = response.data.beneficiaries
              ? response.data.beneficiaries[0]
              : null;

            if (beneficiaryId && !editReserveData) {
              await mailToBeneficiary(response.data._id, beneficiaryId);
            }
          }

          optionHandler();
        } else {
        }
      } catch (error) {
        console.error(error.message);

        setNotificationString("Something went wrong, please try again later");
        setError(true);
      }
    } else {
      setIsRequiredFieldFillPending(true);
    }
  };

  //function for call api react select
  const optionHandler = () => {
    getAllCourseList();
    getAllActivityList();
    getAllEducators();
    getAllClient();
    getAllObserver();
    getAllReserveTemplate();
    getAllLabels();
  };

  //function for reset
  const resetHandler = () => {
    for (let reserveStatus of ReserveStatusOptions) {
      if (reserveStatus.value == "3") {
        statusSelectionHandler(reserveStatus);
      }
    } //set status value

    if (editReserveData) {
      setEditReserveData(null);
    }
    if (copyReserveData) {
      setCopyReserveData(null);
    }
    if (addMoreConsecutiveReserveData) {
      setaddMoreConsecutiveReserveData(null);
    }

    setReserveId(null);
    setAmbReserveId(null);
    setStartDate(dateOfToday);
    setstartTime(getCurrentTime());
    setEndTime(getCurrentTime());
    setActivityCodeId("");
    setActivityHours("");
    setNoOfEducators("1");
    setNoOfParticipants("");
    setNoParticipantsReal("");
    setBillingUnit("");
    setTutor("");
    setGrade("");
    setNoOfCompanions("");
    setLocation("");
    setZipCode("");
    setTown("");
    setCity("");
    setStateName("");
    setCountry("");
    setResponsileName("");
    setResponsileEmail("");
    setResponsilePhone("");
    setComments("");
    setInternalComments("");
    // setStatusData(null);
    // setCourseData(null);
    setActivityData(null);
    setActivityTypeData(null);
    setEducatorVailableMessage([]);
    setEducatorData([]);
    setLevelData([]);
    setSpecialNeedsData([]);
    setBatchData(null);
    setSpecificAudienceData([]);
    setClientData([]);
    setObserverData([]);
    setBenificiaryData([]);
    setParticipantsData([]);
    setResponsibleData([]);
    setLabelData([]);
    setIsTemplate(false);
    setTemplateName("");
    setIsConsecutive(false);

    setConsecutiveReserveId(null);
    setChangeSelect((prevState) => ({
      ...prevState,
      selectedTemplate: null,
      // selectedStatus: null,
      // selectedCourses: null,
      selectedActivity: null,
      selectedActivityType: null,
      selectedEducators: null,
      selectedParticipants: null,
      selectedScope: null,
      selectedSpecialNeeds: null,
      selectedBatch: null,
      selectedClient: null,
      selectedObserver: null,
      selectedAudience: null,
      selectedBenificiary: null,
      selectLabels: null,
      selectedResponsible: null,
    }));

    setValidation({
      startDateWarning: false,
      startTimeWarning: false,
      endTimeWarning: false,
      noOfEducatorsWarning: false,
      noOfParticipantsWarning: false,
      noParticipantsRealWarning: false,
      billingUnitWarning: false,
      noOfCompanionsWarning: false,
      locationWarning: false,
      zipcodewarning: false,
      responsileNameWarning: false,
      responsileWarning: false,
      responsilePhoneWarning: false,
      commentsWarning: false,
      internalCommentWarning: false,
      statusWarning: false,
      courseWarning: false,
      activityWarning: false,
      activityTypeWarning: false,
      educatorWarning: false,
      levelWarning: false,
      specialNeedsWarning: false,
      batchWarning: false,
      specificAudienceWarning: false,
      clientWarning: false,
      observerWarning: false,
      benificiaryWarning: false,
      participantsWarning: false,
      timeCompareWarning: false,
    });
    setAssociatedConsecutiveList([]);
    setKitAvailabilityMessages([]);
  };

  /* function to send mail to beneficiary */
  const mailToBeneficiary = async (reserveId, mailReceiverId) => {
    if (mailReceiverId) {
      const mailData = {
        reserve: reserveId,
        template: "reserve-info",
        receiverid: mailReceiverId,
      };

      try {
        let requestUrl =
          url.API_BASEURL +
          url.API_SEND_MESSAGE_BY_TEMPLATE +
          `?token=${token}`;

        const response = await postData(requestUrl, mailData);

        if (response.status) {
          console.log(response);
          setNotificationString("Mail sent to beneficiary");
          setSuccess(true);
        }
      } catch (error) {
        console.error(error.message);
      }
    } else {
      setNotificationString(
        "No beneficiary/responsible associated with the reserve"
      );
      setSuccess(true);
    }
  };

  //assign new added clint
  useEffect(() => {
    if (newAddedClientResult.length > 0) {
      selectClientHandler(newAddedClientResult);
      setNewAddedClientResult([]);
    }
  }, [newAddedClientResult]);

  //assign new added beneficiary
  useEffect(() => {
    if (newAddedBeneficiaryResult.length > 0) {
      selectBenificiaryHandler(newAddedBeneficiaryResult);
      setNewAddedBeneficiaryResult([]);
    }
  }, [newAddedBeneficiaryResult]);

  //assign new added observer
  useEffect(() => {
    if (newAddedObserverResult.length > 0) {
      selectObserverHandler(newAddedObserverResult);
      setNewAddedObserverResult([]);
    }
  }, [newAddedObserverResult]);

  //assign new added participants
  useEffect(() => {
    if (newAddedParticipantResult.length > 0) {
      setNoOfParticipants("1");
      selectParticipantsHandler(newAddedParticipantResult, "1");
      setNewAddedParticipantResult([]);
    }
  }, [newAddedParticipantResult]);

  //assign new added responsible
  useEffect(() => {
    if (newAddedResponsibleResult.length > 0) {
      responsibleSelectionHandler(newAddedResponsibleResult);
      setNewAddedResponsibleResult([]);
    }
  }, [newAddedResponsibleResult]);

  //use effect for assign edit data
  useEffect(() => {
    if (editReserveData) {
      getReserveDetails(editReserveData.reserveid);
    }
  }, [editReserveData]);

  //use effect for copy reserve data
  useEffect(() => {
    if (copyReserveData) {
      getReserveDetails(copyReserveData, true);
    }
  }, [copyReserveData]);

  //use effect for add more consecutive
  useEffect(() => {
    if (addMoreConsecutiveReserveData) {
      getReserveDetails(addMoreConsecutiveReserveData);
    }
  }, [addMoreConsecutiveReserveData]);

  useEffect(() => {
    if (resetReserveSaveForm) {
      resetHandler();
      optionHandler();
      setResetReserveSaveForm(false);
    }
  }, [resetReserveSaveForm]);

  useEffect(() => {
    if (reloadReserveFormOptions) {
      optionHandler();
      setReloadReserveFormOptions(false);
    }
  }, [reloadReserveFormOptions]);

  useEffect(() => {
    optionHandler();
  }, []);

  useEffect(() => {
    mapShiftData();
  }, [startTime, endTime]);

  return (
    <div
      className="offcanvas offcanvas-end border-0"
      tabIndex="-1"
      id="saveReservationPopup"
      aria-labelledby="saveReservationPopupLabel"
    >
      <div className="offcanvas-header position-relative align-items-start p-4">
        <h3 className="text-secondary mb-0" id="saveReservationPopupLabel">
          {addMoreConsecutiveReserveData
            ? t("Add more Consecutive Reserve")
            : editReserveData
            ? editReserveData?.reserveid + " " + t("Save Reserve")
            : t("New Save Reserve")}{" "}
        </h3>
        <button
          type="button"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body position-relative p-4 pt-0">
        <form onSubmit={(e) => e.preventDefault()}>
          {/* ------ template selection and checkbox section start ------ */}
          <div className="form-group mb-4">
            {/* ======== select Template start =========*/}
            {editReserveData ? null : (
              <label htmlFor="selectTemplate">{t("Select Template")}</label>
            )}
            {editReserveData ? null : (
              <Select
                placeholder={t("Select reserve template")}
                className="nosearch_select"
                options={reserveTemplateList}
                value={changeSelect.selectedTemplate}
                onChange={(value) => {
                  reserveTemplateSelectionHandler(value);
                }}
              />
            )}
            {/* ======== select Template end =========*/}

            {/* ======== save as Template checkbox start =========*/}
            <div className="form-check mt-3">
              <input
                className="form-check-input"
                type="checkbox"
                id="saveAsReserveTemplate"
                checked={isTemplate}
                onChange={(e) => {
                  setIsTemplate(e.target.checked);
                }}
              />
              <p
                className="text-primary fw-medium"
                htmlFor="saveAsReserveTemplate"
              >
                {t("Save as Template")}
              </p>
            </div>
            {/* ======== save as Template checkbox end =========*/}
          </div>
          {/* ------ template selection and checkbox section end ------ */}

          {/* ========== Template name section start ============ */}
          {isTemplate && (
            <div className="form-group mb-4">
              <label htmlFor="activity">{t("Template name")}</label>
              <input
                type="text"
                id="billingUnit"
                className="form-control"
                value={templateName}
                onChange={(e) => {
                  setTemplateName(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    templateWarning: false,
                  }));
                  setIsRequiredFieldFillPending(false);
                }}
              />
              {/* activity warning */}
              {validation.templateWarning && (
                <p className="mt-2 d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter template name")} !</span>
                </p>
              )}
            </div>
          )}
          {/* ========== Template name section end ============ */}

          {/* ======== Consecutive checkbox section start =========*/}
          <div className="form-group mb-4 form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="consecutiveReserve"
              checked={isConsecutive}
              onChange={(e) => {
                setIsConsecutive(e.target.checked);
              }}
            />
            <p className="text-primary fw-medium">
              {t("Save as Consecutive Reserve")}
            </p>
          </div>
          {/* ======== Consecutive checkbox section end =========*/}

          {/* ====== associcated consecutive reservelist section start ===== */}
          {associatedConsecutiveList.length > 0 ? (
            <div className="form-group mb-3 form-check">
              {associatedConsecutiveList.map((associatedReserve, index) => {
                return (
                  <p
                    key={index}
                    className="text-primary fw-medium mb-0"
                    htmlFor="saveAsConsecutiveReserveTemplate"
                  >
                    {associatedReserve.reserve} ,
                  </p>
                );
              })}
            </div>
          ) : null}
          {/* ====== associcated consecutive reservelist section end ===== */}

          {/* ========== Select activity start ============ */}
          <div className="form-group mb-4">
            <label htmlFor="activity">{t("Activity")}</label>
            <Select
              className="nosearch_select"
              placeholder={t("Select Activity")}
              options={activityList}
              value={changeSelect.selectedActivity}
              onChange={(val) => {
                activitySelectionHandler(val);
                setValidation((prevState) => ({
                  ...prevState,
                  activityWarning: false,
                }));
                setIsRequiredFieldFillPending(false);
              }}
            />
            {/* activity warning */}
            {validation.activityWarning && (
              <p className="mt-2 d-flex align-items-center gap-1 text-danger">
                <span className="material-symbols-outlined">warning</span>
                <span>{t("Please select activity")} !</span>
              </p>
            )}
          </div>
          {/* ========== Select Activity end ============ */}

          {/* ======= activity duration section start ====== */}
          {activityHours == "" ? null : (
            <div className="col-lg-12 mb-3">
              <p className="mb-0 fw-bold">
                {t("Activity Duration")} : {activityHours} {t("Min")}
              </p>
            </div>
          )}
          {/* ======= activity duration section end ====== */}

          {/* =========== select activity type starts =================*/}
          <div className="form-group mb-4">
            <label htmlFor="typeOfActivity">{t("Type of Activity")}</label>
            <Select
              placeholder={t("Select type of activity")}
              className="nosearch_select"
              options={typeOfActivity}
              value={changeSelect.selectedActivityType}
              onChange={(val) => {
                selectActivityTypeHandler(val);
                setValidation((prevState) => ({
                  ...prevState,
                  activityTypeWarning: false,
                }));
              }}
            />
            {/* Activity type warning */}
            {validation.activityTypeWarning && (
              <p className="mt-2 d-flex align-items-center gap-1 text-danger">
                <span className="material-symbols-outlined">warning</span>
                <span>{t("Please enter type of activity")}!</span>
              </p>
            )}
          </div>
          {/* =========== select activity type ends =================*/}

          {/* ======== status and course section start ======= */}
          <div className="form-group mb-4">
            <div className="row">
              {/* ======== select status start =========*/}
              <div className="col-lg-6">
                <label htmlFor="status">{t("Status")}</label>
                <Select
                  className="nosearch_select"
                  // options={StatusOptions}
                  options={ReserveStatusOptions.map((option) => ({
                    ...option,
                    label: t(option.label), // Translate the label using the t function
                  }))}
                  value={changeSelect.selectedStatus}
                  onChange={(val) => {
                    statusSelectionHandler(val),
                      setValidation((prevState) => ({
                        ...prevState,
                        statusWarning: false,
                      }));
                  }}
                />
                {/* status warning */}
                {validation.statusWarning && (
                  <p className="mt-2 d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter status")}!</span>
                  </p>
                )}
              </div>
              {/* ======== select status end =========*/}

              {/* ======== select courses start =========*/}
              <div className="col-lg-6">
                <label htmlFor="course">{t("Course")}</label>
                <Select
                  className="nosearch_select"
                  options={courseList}
                  value={changeSelect.selectedCourses}
                  onChange={(val) => {
                    courseSelectionHandler(val),
                      setValidation((prevState) => ({
                        ...prevState,
                        courseWarning: false,
                      }));
                  }}
                />
                {/* Course warning */}
                {validation.courseWarning && (
                  <p className="mt-2 d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter Course")}!</span>
                  </p>
                )}
              </div>
              {/* ======== select courses end =========*/}
            </div>
          </div>
          {/* ======== status and course section end ======= */}

          {/* ========== date/time section start ============ */}
          <div className="form-group mb-4">
            <div className="row">
              <div className="col-lg-4">
                <label htmlFor="date">{t("Date")}</label>
                <input
                  type="date"
                  id="date"
                  className="form-control"
                  placeholder="DD/MM/YYYY"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);

                    checkisHoliday(e.target.value);

                    checkMaterialKitAvailable(e.target.value);

                    setValidation((prevState) => ({
                      ...prevState,
                      startDateWarning: false,
                    }));
                  }}
                />
                {/* start date warning */}
                {validation.startDateWarning && (
                  <p className="mt-2 d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter date")}!</span>
                  </p>
                )}
                {holidaySelected && (
                  <p className="mt-2 d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Its a Holiday!")}!</span>
                  </p>
                )}

                {kitAvailabilityMessages.map((item, index) => (
                  <p
                    key={index}
                    className={`mt-2 d-flex align-items-center gap-1 ${
                      item.availability ? "text-success" : "text-danger"
                    }`}
                    style={{ width: "500px" }}
                  >
                    {item.availability == true ? (
                      <span className="material-symbols-outlined">check</span>
                    ) : (
                      <span className="material-symbols-outlined">warning</span>
                    )}

                    <span>
                      Kit: {item.label} {" || "}
                      Reserved : {item.reserved} {" || "}
                      Units by Day: {item.unitsperday}
                    </span>
                  </p>
                ))}
              </div>

              <div className="col-lg-4">
                <label htmlFor="startTime">{t("Start Time")}</label>
                <input
                  type="time"
                  id="startTime"
                  className="form-control"
                  placeholder="HH : MM"
                  value={startTime}
                  onChange={(e) => {
                    setstartTime(e.target.value);

                    console.log("activityHours", activityHours);

                    if (activityHours != "") {
                      const getEndTimeValue = getEndTime(
                        activityHours,
                        e.target.value
                      );

                      console.log("getEndTimeValue", getEndTimeValue);

                      setEndTime(getEndTimeValue);
                    }

                    setValidation((prevState) => ({
                      ...prevState,
                      startTimeWarning: false,
                      timeCompareWarning: false,
                    }));
                  }}
                />
                {/* start time warning */}
                {validation.startTimeWarning && (
                  <p className="mt-2 d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter start time")}!</span>
                  </p>
                )}
              </div>
              <div className="col-lg-4">
                <label htmlFor="endTime">{t("End Time")}</label>
                <input
                  type="time"
                  id="endTime"
                  className="form-control"
                  placeholder="HH : MM"
                  value={endTime}
                  onChange={(e) => {
                    setEndTime(e.target.value);

                    setValidation((prevState) => ({
                      ...prevState,
                      endTimeWarning: false,
                      timeCompareWarning: false,
                    }));
                  }}
                />
                {/* start time warning */}
                {validation.endTimeWarning && (
                  <p className="mt-2 d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter end time")}!</span>
                  </p>
                )}
                {/* time compare warning */}
                {validation.timeCompareWarning && (
                  <p className="mt-2 d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("End time should be greater start time")}!</span>
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-12 mb-3">
            <p className="mb-0 fw-bold">
              {t("Shift")} : {shift == "1" ? t("Morning") : ""}{" "}
              {shift == "2" ? t("Evening") : ""}
            </p>
          </div>
          {/* ========== date/time section end ============ */}

          <div className="form-group mb-4">
            <div className="row">
              {/* ========= no of educator section start ========= */}
              <div className="col-lg-3">
                <label htmlFor="numberOfEducators">
                  {t("Number of Educators")}
                </label>
                <input
                  type="text"
                  id="numberOfEducators"
                  className="form-control"
                  value={noOfEducators}
                  onChange={(e) => {
                    setNoOfEducators(e.target.value);
                    setValidation((prevState) => ({
                      ...prevState,
                      noOfEducatorsWarning: false,
                    }));
                  }}
                />
                {/* start date warning */}
                {validation.noOfEducatorsWarning && (
                  <p className="mt-2 d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter number of educators")}!</span>
                  </p>
                )}
              </div>
              {/* ========= no of educator section end ========= */}

              {/* ========= no of participants section start ========= */}
              <div className="col-lg-3">
                <label htmlFor="numberOfParticipants">
                  {t("Number of Participants")}
                </label>
                <input
                  type="text"
                  id="numberOfParticipants"
                  className="form-control"
                  value={noOfParticipants}
                  onChange={(e) => {
                    setNoOfParticipants(e.target.value);
                    // setValidation((prevState) => ({
                    //   ...prevState,
                    //   noOfParticipantsWarning: false,
                    // }));
                  }}
                />
                {/* start date warning */}
                {/* {validation.noOfParticipantsWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t("Please enter number of participants")}!</span>
                    </p>
                  </div>
                )} */}
              </div>
              {/* ========= no of participants section start ========= */}

              {/* ========= no of real participants section start ========= */}
              <div className="col-lg-3">
                <label htmlFor="numberParticipantsReal">
                  {t("Number Participants Real")}
                </label>
                <input
                  type="text"
                  id="numberParticipantsReal"
                  className="form-control"
                  value={noParticipantsReal}
                  onChange={(e) => {
                    setNoParticipantsReal(e.target.value);
                    // setValidation(prevState => ({
                    //   ...prevState,
                    //   noParticipantsRealWarning: false,
                    // }));
                  }}
                />
                {/* Participants Real warning */}
                {/* {validation.noParticipantsRealWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t('Please enter participants real')}!</span>
                    </p>
                  </div>
                )} */}
              </div>
              {/* ========= no of real participants section start ========= */}

              {/* ========= no billing unit section start ========= */}
              <div className="col-lg-3">
                <label htmlFor="billingUnit">{t("Billing Unit")}</label>
                <input
                  type="text"
                  id="billingUnit"
                  className="form-control"
                  // placeholder="Enter Here"
                  value={billingUnit}
                  onChange={(e) => {
                    setBillingUnit(e.target.value);
                    // setValidation(prevState => ({
                    //   ...prevState,
                    //   billingUnitWarning: false,
                    // }));
                  }}
                />
                {/* billing unit warning */}
                {/* {validation.billingUnitWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t('Please enter billing unit')}!</span>
                    </p>
                  </div>
                )} */}
              </div>
              {/* ========= no billing unit section end ========= */}
            </div>
          </div>

          <div className="form-group mb-4">
            <div className="row">
              {/* ------- tutor section start ------- */}
              <div className="col-lg-6">
                <label htmlFor="Tutor">{t("Tutor")}</label>
                <input
                  type="text"
                  id="billingUnit"
                  className="form-control"
                  value={tutor}
                  onChange={(e) => {
                    setTutor(e.target.value);
                  }}
                />
              </div>
              {/* ------- tutor section end ------- */}

              {/* ------- grade section start ------- */}
              <div className="col-lg-6">
                <label htmlFor="Grade">{t("Grade")}</label>
                <input
                  type="text"
                  id="grade"
                  className="form-control"
                  value={grade}
                  onChange={(e) => {
                    setGrade(e.target.value);
                  }}
                />
              </div>
              {/* ------- grade section end ------- */}
            </div>
          </div>

          {/* ============ select educators start ============= */}
          <div className="row">
            <div className="col-lg-9">
              <div className="form-group mb-4">
                <label htmlFor="educators">{t("Educators")}</label>
                <Select
                  isMulti
                  placeholder={t("Select educators")}
                  className="nosearch_select"
                  options={educatorList}
                  value={changeSelect.selectedEducators}
                  getOptionLabel={getOptionLabel}
                  onChange={(val) => {
                    educatorsSelectionHandler(val, noOfEducators);

                    // setValidation((prevState) => ({
                    //   ...prevState,
                    //   educatorWarning: false,
                    // }));
                  }}
                />
                {/* Educators warning */}
                {/* {validation.educatorWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter educators")}!</span>
                </p>
              </div>
            )} */}
              </div>
            </div>
            {/* <div className="col-lg-3 d-flex align-items-center justify-content-end">
              <button
                type="button"
                className="btn btn-sm btn-primary"
                onClick={checkEducatorAvailibility}
              >
                {t("Check")}
              </button>
            </div> */}
          </div>
          {/* ============ select educators end ============= */}

          {/* ------ educator availibility message section start ------- */}
          <div className="form-group mb-2">
            <ul className="d-flex gap-2 list-unstyled">
              {educatorVailableMessage.map((educator, index) => {
                return (
                  <li key={index}>
                    <span className="text-primary me-2">{educator.user}</span>:
                    <span
                      className={
                        educator.availability == "0"
                          ? "ms-2 text-danger"
                          : "ms-2 text-success"
                      }
                    >
                      {educator.availability == "0"
                        ? t("Not Available")
                        : t("Available")}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
          {/* ------ educator availibility message section end ------- */}

          {/* ============ select level start ============= */}
          <div className="form-group mb-4">
            <label htmlFor="Levels">{t("Levels")}</label>
            <Select
              placeholder={t("Select Levels")}
              className="nosearch_select"
              options={levelOption}
              isMulti
              value={changeSelect.selectedScope}
              onChange={(val) => {
                levelSelectionHandler(val);
                // setValidation(prevState => ({
                //   ...prevState,
                //   levelWarning: false,
                // }));
              }}
            />
            {/* levels warning */}
            {/* {validation.levelWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter levels')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* ============ select level end ============= */}

          {/* ========= specific needs start =========== */}
          <div className="form-group mb-4">
            <label htmlFor="scope">{t("Specific Needs")}</label>
            <Select
              placeholder={t("Select specific needs")}
              className="nosearch_select"
              options={SpecialNeeds}
              isMulti
              value={changeSelect.selectedSpecialNeeds}
              onChange={(val) => {
                selectSpecialNeedsHandler(val);
                // setValidation(prevState => ({
                //   ...prevState,
                //   specialNeedsWarning: false,
                // }));
              }}
            />
          </div>
          {/* ========= specific needs end =========== */}

          {/* batch,Specific Audience and Number of Companions start */}
          <div className="form-group mb-4">
            <div className="row">
              {/* ============ select batch start ============= */}
              <div className="col-lg-4">
                <label htmlFor="batch">{t("Batch")}</label>
                <Select
                  placeholder={t("Select batch")}
                  className="nosearch_select"
                  options={batchLotOption}
                  value={changeSelect.selectedBatch}
                  onChange={(val) => {
                    batchSelectionHandler(val);
                  }}
                />
              </div>
              {/* ============ select batch end ============= */}

              {/* -------- Specific Audience section start ------- */}
              <div className="col-lg-4">
                <label htmlFor="specificAudience">
                  {t("Specific Audience")}
                </label>
                <Select
                  placeholder={t("Select specific audience")}
                  className="nosearch_select"
                  isMulti
                  options={specificAudience}
                  value={changeSelect.selectedAudience}
                  onChange={(val) => {
                    specificAudienceHandler(val);
                  }}
                />
              </div>
              {/* -------- Specific Audience section end ------- */}

              {/* ------ Number of Companions section start ------- */}
              <div className="col-lg-4">
                <label htmlFor="numberOfCompanions">
                  {t("Number of Companions")}
                </label>
                <input
                  type="text"
                  id="numberOfCompanions"
                  className="form-control"
                  placeholder={t("Companions Numbers")}
                  value={noOfCompanions}
                  onChange={(e) => {
                    setNoOfCompanions(e.target.value);
                  }}
                />
              </div>
              {/* ------ Number of Companions section end ------- */}
            </div>
          </div>
          {/* batch,Specific Audience and Number of Companions end */}

          <div className="form-group mb-4">
            <div className="row">
              {/* ============ select clients starts ============= */}
              <div className="col-lg-11">
                <label htmlFor="client">{t("Client")}</label>
                <Select
                  placeholder={t("Select client")}
                  className="nosearch_select"
                  isMulti
                  options={clientList}
                  value={changeSelect.selectedClient}
                  onChange={(val) => {
                    selectClientHandler(val);
                    setValidation((prevState) => ({
                      ...prevState,
                      clientWarning: false,
                    }));
                    setIsRequiredFieldFillPending(false);
                  }}
                />
                {/* client warning warning */}
                {validation.clientWarning && (
                  <p className="mt-2 d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please select client")}!</span>
                  </p>
                )}
              </div>
              <div className="col-lg-1">
                <Link
                  to="#saveClientPopup"
                  data-bs-toggle="offcanvas"
                  role="button"
                  aria-controls="saveReservationPopup"
                  className="btn btn-primary icon-btn mw-unset mt-4"
                  onClick={() => {
                    setUserFormRole("client");
                  }}
                >
                  <span
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    className="d-block material-symbols-outlined"
                  >
                    add
                  </span>
                </Link>
              </div>
              {/* ============ select clients ends ============= */}
            </div>
          </div>

          {/* ------- beneficiary and responsible section start ------- */}
          <div className="form-group mb-4">
            <div className="row">
              {/* ============ select beneficiary start ============= */}
              <div className="col-lg-5">
                <label htmlFor="beneficiary">{t("Beneficiary")}</label>
                <Select
                  placeholder={t("Select beneficiary")}
                  className="nosearch_select"
                  options={benificiaryList}
                  isMulti
                  value={changeSelect.selectedBenificiary}
                  onChange={(val) => {
                    selectBenificiaryHandler(val);
                    // setValidation((prevState) => ({
                    //   ...prevState,
                    //   benificiaryWarning: false,
                    // }));
                  }}
                />
              </div>
              <div className="col-lg-1">
                <Link
                  to="#saveClientPopup"
                  data-bs-toggle="offcanvas"
                  role="button"
                  aria-controls="saveReservationPopup"
                  className="btn btn-primary icon-btn mw-unset mt-4"
                  onClick={() => {
                    setUserFormRole("beneficiary");
                  }}
                >
                  <span
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    className="d-block material-symbols-outlined"
                  >
                    add
                  </span>
                </Link>
              </div>
              {/* ============ select beneficiary end ============= */}

              {/* ============ select responsible starts ============= */}
              <div className="col-lg-5">
                <label htmlFor="participants">{t("Responsible")}</label>
                <Select
                  isMulti
                  placeholder={t("Select Responsible")}
                  className="nosearch_select"
                  options={responsibleList}
                  value={changeSelect.selectedResponsible}
                  onChange={(val) => {
                    responsibleSelectionHandler(val, noOfParticipants);
                  }}
                />
                {validation.responsileWarning && (
                  <p className="mt-2 d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please select responsible")}!</span>
                  </p>
                )}
              </div>
              <div className="col-lg-1">
                <Link
                  to="#quickSaveUserPopup"
                  data-bs-toggle="offcanvas"
                  role="button"
                  aria-controls="saveReservationPopup"
                  className="btn btn-primary icon-btn mw-unset mt-4"
                  onClick={() => {
                    setUserFormRole("responsible");
                  }}
                >
                  <span
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    className="d-block material-symbols-outlined"
                  >
                    add
                  </span>
                </Link>
              </div>
              {/* ============ select responsible end ============= */}
            </div>
          </div>
          {/* ------- beneficiary,client and observer section end ------- */}

          {/* ============= participants, responsible select section start ===================== */}
          <div className="form-group mb-4">
            <div className="row">
              {/* ============ select participants start ============= */}
              <div className="col-lg-5">
                <label htmlFor="participants">{t("Participants")}</label>
                <Select
                  isMulti
                  placeholder={t("Select participants")}
                  className="nosearch_select"
                  options={participantList}
                  value={changeSelect.selectedParticipants}
                  onChange={(val) => {
                    selectParticipantsHandler(val, noOfParticipants);
                    setValidation((prevState) => ({
                      ...prevState,
                      participantsWarning: false,
                    }));
                  }}
                />
                {/* ParticipantsWarning warning */}
                {validation.participantsWarning && (
                  <p className="mt-2 d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter participants")}!</span>
                  </p>
                )}
              </div>
              <div className="col-lg-1">
                <Link
                  to="#quickSaveUserPopup"
                  data-bs-toggle="offcanvas"
                  role="button"
                  aria-controls="saveReservationPopup"
                  className="btn btn-primary icon-btn mw-unset mt-4"
                  onClick={() => {
                    setUserFormRole("participant");
                  }}
                >
                  <span
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    className="d-block material-symbols-outlined"
                  >
                    add
                  </span>
                </Link>
              </div>
              {/* ============ select participants end ============= */}

              {/* ============ select observer starts ============= */}
              <div className="col-lg-5">
                <label htmlFor="observer">{t("Observer")}</label>
                <Select
                  placeholder={t("Select observer")}
                  className="nosearch_select"
                  options={observerList}
                  isMulti
                  value={changeSelect.selectedObserver}
                  onChange={(val) => {
                    selectObserverHandler(val);
                    // setValidation((prevState) => ({
                    //   ...prevState,
                    //   observerWarning: false,
                    // }));
                  }}
                />
                {/* observer warning */}
                {/* {validation.observerWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t("Please enter observer")}!</span>
                    </p>
                  </div>
                )} */}
              </div>
              <div className="col-lg-1">
                <Link
                  to="#quickSaveUserPopup"
                  data-bs-toggle="offcanvas"
                  role="button"
                  aria-controls="saveReservationPopup"
                  className="btn btn-primary icon-btn mw-unset mt-4"
                  onClick={() => {
                    setUserFormRole("observer");
                  }}
                >
                  <span
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    className="d-block material-symbols-outlined"
                  >
                    add
                  </span>
                </Link>
              </div>
              {/* ============ select observer ends ============= */}
            </div>
          </div>
          {/* ============= participants select section end ===================== */}

          {/* ======= zipcode section start ======= */}
          <div className="form-group mb-4">
            <label htmlFor="location">{t("Zipcode")}</label>
            <div className="field-container d-flex align-items-center gap-3">
              <input
                type="text"
                id="zipcode"
                className="form-control"
                placeholder={t("Enter zipcode")}
                value={zipCode}
                onChange={(e) => {
                  setZipCode(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    zipcodewarning: false,
                  }));
                  setIsRequiredFieldFillPending(false);
                }}
              />
              {/* Location warning */}
              {validation.zipcodewarning && (
                <p className="mt-2 d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter zipcode")}!</span>
                </p>
              )}
              <button
                className="btn btn-sm btn-primary"
                onClick={getLocationFromZip}
                type="button"
              >
                {t("Fetch location by zipcode")}
              </button>
            </div>
          </div>
          {/* ======= zipcode section end ======= */}

          {/*========== location and zipcode section start ==========*/}
          <div className="form-group mb-3">
            <div className="row">
              {/*========== location start ==========*/}
              <div className="col-lg-6">
                <div className="form-group mb-4">
                  <label htmlFor="location">{t("Location")}</label>
                  <div className="field-container position-relative">
                    <input
                      type="text"
                      id="location"
                      className="form-control"
                      placeholder={t("Enter Address")}
                      value={location}
                      onChange={(e) => {
                        setLocation(e.target.value);
                        setValidation((prevState) => ({
                          ...prevState,
                          locationWarning: false,
                        }));
                        setIsRequiredFieldFillPending(false);
                      }}
                    />
                    {/* Location warning */}
                    {validation.locationWarning && (
                      <p className="mt-2 d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t("Please enter location")}!</span>
                      </p>
                    )}
                    {/* <span className="d-block material-symbols-outlined icon-md text-gray position-absolute top-50 end-0 translate-middle-y me-3">
                      my_location
                    </span> */}
                  </div>
                </div>
              </div>
              {/*========== location end ==========*/}

              {/*========== town section start ==========*/}
              <div className="col-lg-6">
                <div className="form-group mb-4">
                  <label htmlFor="location">{t("Town")}</label>
                  <div className="field-container position-relative">
                    <input
                      type="text"
                      id="town"
                      className="form-control"
                      placeholder={t("Enter Town")}
                      value={town}
                      onChange={(e) => {
                        setTown(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              {/*========== town section end ==========*/}
            </div>
          </div>
          {/*========== location and zipcode section end ==========*/}

          {/*========== city,state and country section start ==========*/}
          <div className="form-group mb-3">
            <div className="row">
              {/*========== city section start ==========*/}
              <div className="col-lg-4">
                <div className="form-group mb-4">
                  <label htmlFor="location">{t("City")}</label>
                  <div className="field-container position-relative">
                    <input
                      type="text"
                      id="location"
                      className="form-control"
                      placeholder={t("Enter City")}
                      value={city}
                      onChange={(e) => {
                        setCity(e.target.value);
                        setValidation((prevState) => ({
                          ...prevState,
                          cityWarning: false,
                        }));
                        setIsRequiredFieldFillPending(false);
                      }}
                    />
                    {/* Location warning */}
                    {validation.cityWarning && (
                      <p className="mt-2 d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t("Please enter city")}!</span>
                      </p>
                    )}
                  </div>
                </div>
              </div>
              {/*========== city section end ==========*/}

              {/*========== state section start ==========*/}
              <div className="col-lg-4">
                <div className="form-group mb-4">
                  <label htmlFor="location">{t("State")}</label>
                  <div className="field-container position-relative">
                    <input
                      type="text"
                      id="zipcode"
                      className="form-control"
                      placeholder={t("Enter State")}
                      value={stateName}
                      onChange={(e) => {
                        setStateName(e.target.value);
                        setValidation((prevState) => ({
                          ...prevState,
                          stateWarning: false,
                        }));
                        setIsRequiredFieldFillPending(false);
                      }}
                    />
                    {/* Location warning */}
                    {validation.stateWarning && (
                      <p className="mt-2 d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t("Please enter state")}!</span>
                      </p>
                    )}
                  </div>
                </div>
              </div>
              {/*========== state section end ==========*/}

              {/*========== country section start ==========*/}
              <div className="col-lg-4">
                <div className="form-group mb-4">
                  <label htmlFor="location">{t("Country")}</label>
                  <div className="field-container position-relative">
                    <input
                      type="text"
                      id="zipcode"
                      className="form-control"
                      placeholder={t("Enter Country")}
                      value={country}
                      onChange={(e) => {
                        setCountry(e.target.value);
                        setValidation((prevState) => ({
                          ...prevState,
                          countryWarning: false,
                        }));
                        setIsRequiredFieldFillPending(false);
                      }}
                    />
                    {/* Location warning */}
                    {validation.countryWarning && (
                      <p className="mt-2 d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t("Please enter country")}!</span>
                      </p>
                    )}
                  </div>
                </div>
              </div>
              {/*========== country section end ==========*/}
            </div>
          </div>
          {/*========== city,state and country section start ==========*/}

          {/* ============ educator comment section start ================== */}
          <div className="form-group mb-4">
            <label htmlFor="educatorComments">{t("Educator Comments")}</label>
            <textarea
              cols="30"
              rows="4"
              className="form-control"
              placeholder={t("Enter Comments")}
              value={comments}
              onChange={(e) => {
                setComments(e.target.value);
                // setValidation(prevState => ({
                //   ...prevState,
                //   commentsWarning: false,
                // }));
              }}
            ></textarea>
            {/* comments warning */}
            {/* {validation.commentsWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter comments')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* ============ educator comment section end ================== */}

          {/* ============ internalcomments section start ================== */}
          <div className="form-group mb-4">
            <label htmlFor="internalComments">{t("Internal Comments")}</label>
            <textarea
              cols="30"
              rows="4"
              className="form-control"
              placeholder={t("Enter Comments")}
              value={internalComments}
              onChange={(e) => {
                setInternalComments(e.target.value);
                // setValidation(prevState => ({
                //   ...prevState,
                //   commentsWarning: false,
                // }));
              }}
            ></textarea>
            {/* internalcomments warning */}
            {/* {validation.internalCommentWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter internalcomments")}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* ============ internalcomments section end ================== */}

          {/* ============ Label starts ================ */}
          <div className="form-group mb-4">
            <label htmlFor="educators">{t("Labels")}</label>
            <Select
              isMulti
              placeholder={t("Select label")}
              className="nosearch_select"
              options={labelList}
              value={changeSelect.selectLabels}
              onChange={(val) => {
                labelSelectionHandler(val);
                // setValidation(prevState => ({
                //   ...prevState,
                //   participantsWarning: false,
                // }));
              }}
            />
            {/* ParticipantsWarning warning */}
            {/* {validation.participantsWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter participants')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* ============ tags ends ================ */}

          {/* ============ save button section start ================== */}
          <div className="action d-flex align-items-center gap-3">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={isSaving ? true : false}
              style={{ cursor: isSaving ? "not-allowed" : "pointer" }}
              onClick={saveReservationHandler}
            >
              {t("Save")}
              {isSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
            {isRequiredFieldFillPending && (
              <p className="d-flex align-items-center gap-1 text-danger">
                <span className="material-symbols-outlined">warning</span>
                <span>{t("Please fill the required input fields")}!!!</span>
              </p>
            )}
          </div>
          {/* ============ save button section end ================== */}
        </form>
      </div>
    </div>
  );
};

export default ReservationSavePopup;
