/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
//import translation function for language translation
import { useTranslation } from "react-i18next";
import { assetImages } from "../../constants";
/*import url and gateway methods */
import { getData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";
const LeftMenu = () => {
  const token = localStorage.getItem("token"); // token
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const { t } = useTranslation(); //for translation
  const [moduleList, setModuleList] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  let isModConversationExists = false;
  let isModMailTemplateExist = false;
  let isModReserveTemplateExist = false;
  let isModAgendaExists = false;
  let isModBudgetExists = false;
  const [menuPrivilege, setMenuPrivilege] = useState({
    administration: false,
    mailtemplate: false,
    reservetemplate: false,
    question: false,
    holidaycalendar: false,
    activity: false,
    category: false,
    label: false,
    survey: false,
    cost: false,
    materialkit: false,
    concept: false,
    roleprivilege: false,
    users: false,
    reservation: false,
    conversation: false,
    agenda: false,
    budget: false,
  });
  //function for get rolePreviledge details
  const getRolePreviledgeDetails = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ROLE_PRIVILEDGE_DETAILS_BY_ROLEID +
        `?token=${token}&roleid=${userInfo.role._id}`;
      // console.log("Url of previledge details----->", requestUrl);
      const response = await getData(requestUrl);
      // console.log(response);
      if (response.status) {
        setModuleList(response.data.slugdata);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const setAvailableModules = () => {
    if (userInfo?.role?.name !== "admin") {
      setMenuPrivilege((prevState) => ({
        ...prevState,
        administration: moduleList.some(
          (item) => item.slug == "mod_administration"
        ),
        mailtemplate: moduleList.some(
          (item) => item.slug == "mod_mailtemplate"
        ),
        reservetemplate: moduleList.some(
          (item) => item.slug == "mod_reservetemplate"
        ),
        question: moduleList.some((item) => item.slug == "mod_question"),
        holidaycalendar: moduleList.some(
          (item) => item.slug == "mod_holidaycalender"
        ),
        activity: moduleList.some((item) => item.slug == "mod_activity"),
        category: moduleList.some((item) => item.slug == "mod_category"),
        label: moduleList.some((item) => item.slug == "mod_label"),
        survey: moduleList.some((item) => item.slug == "mod_survey"),
        cost: moduleList.some((item) => item.slug == "mod_cost"),
        materialkit: moduleList.some((item) => item.slug == "mod_materialkit"),
        concept: moduleList.some((item) => item.slug == "mod_concept"),
        users: moduleList.some((item) => item.slug == "mod_users"),
        reservation: moduleList.some((item) => item.slug == "mod_reservation"),
        conversation: moduleList.some(
          (item) => item.slug == "mod_conversation"
        ),
        agenda: moduleList.some((item) => item.slug == "mod_agenda"),
        budget: moduleList.some((item) => item.slug == "mod_budget"),
      }));
    }
  };
  useEffect(() => {
    if (userInfo) {
      if (userInfo?.role?.name !== "admin") {
        getRolePreviledgeDetails();
      } else {
        setIsAdmin(true);
      }
    }
  }, []);
  useEffect(() => {
    setAvailableModules();
  }, [moduleList]);
  return (
    <div className="menu_leftpnl d-flex flex-column">
      {/* ----- menu icon section start ----- */}
      <div className="menu_hdng">
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            document.body.classList.remove("add_menupnl");
          }}
        >
          <i className="material-icons-outlined">arrow_back</i>
        </Link>
        <span>{t("Menu")}</span>
      </div>
      {/* ----- menu icon section end ----- */}
      {isAdmin ? (
        // if user is admin -----------------------------------------------------
        <div className="menu_list flex-fill overflow-auto">
          <ul className="list_stye_none">
            {/* ------ dashboard link start ------ */}
            <li>
              <Link
                to="/dashboard"
                onClick={() => {
                  document.body.classList.remove("add_menupnl");
                }}
              >
                <i className="material-icons-outlined">space_dashboard</i>
                <span>{t("Dashboard")}</span>
              </Link>
            </li>
            {/* ------ dashboard link end ------ */}
            {/* ------ administration dropdown link start ------ */}
            <li className="dropdown">
              {/*------ administration button start ------*/}
              <Link to="#" data-bs-toggle="dropdown">
                <i className="material-icons-outlined">manage_accounts</i>
                <span>{t("Administration")}</span>
                <span className="drop_arw">
                  <img src={assetImages.whiteArrow} alt="downarrow" />
                </span>
              </Link>
              {/*------ administration button end ------*/}
              <ul className="list_stye_none dropdown-menu">
                {/*------ mail template link start ------*/}
                <li>
                  <Link
                    to="/mailtemplate"
                    onClick={() => {
                      document.body.classList.remove("add_menupnl");
                    }}
                  >
                    {t("Mail Template")}
                  </Link>
                </li>
                {/*------ mail template link end ------*/}
                {/*------ reserve template link start ------*/}
                <li>
                  <Link
                    to="/reservetemplate"
                    onClick={() => {
                      document.body.classList.remove("add_menupnl");
                    }}
                  >
                    {t("Reserve Template")}
                  </Link>
                </li>
                {/*------ reserve template link end ------*/}
                {/*------ question link start ------*/}
                <li>
                  <Link
                    to="/questions"
                    onClick={() => {
                      document.body.classList.remove("add_menupnl");
                    }}
                  >
                    {t("Questions")}
                  </Link>
                </li>
                {/*------ question link end ------*/}
                {/*------ holidaycalendar link start ------*/}
                <li>
                  <Link
                    onClick={() => {
                      document.body.classList.remove("add_menupnl");
                    }}
                    to="/holidaycalendar"
                  >
                    {t("Holiday Calendar")}
                  </Link>
                </li>
                {/*------ holidaycalendar link end ------*/}
                {/*------ course link start ------*/}
                <li>
                  <Link
                    onClick={() => {
                      document.body.classList.remove("add_menupnl");
                    }}
                    to="/course"
                  >
                    {t("Course")}
                  </Link>
                </li>
                {/*------ course link end ------*/}
                {/*------ activity link start ------*/}
                <li>
                  <Link
                    onClick={() => {
                      document.body.classList.remove("add_menupnl");
                    }}
                    to="/activity"
                  >
                    {t("Activity")}
                  </Link>
                </li>
                {/*------ activity link end ------*/}
                {/*------ category link start ------*/}
                <li>
                  <Link
                    onClick={() => {
                      document.body.classList.remove("add_menupnl");
                    }}
                    to="/category"
                  >
                    {t("Category")}
                  </Link>
                </li>
                {/*------ category link end ------*/}
                {/*------ label link start ------*/}
                <li>
                  <Link
                    onClick={() => {
                      document.body.classList.remove("add_menupnl");
                    }}
                    to="/label"
                  >
                    {t("Label")}
                  </Link>
                </li>
                {/*------ label link end ------*/}
                {/*------ survey link start ------*/}
                <li>
                  <Link
                    onClick={() => {
                      document.body.classList.remove("add_menupnl");
                    }}
                    to="/survey"
                  >
                    {t("Survey")}
                  </Link>
                </li>
                {/*------ survey link end ------*/}
                {/*------ cost link start ------*/}
                <li>
                  <Link
                    onClick={() => {
                      document.body.classList.remove("add_menupnl");
                    }}
                    to="/cost"
                  >
                    {t("Cost")}
                  </Link>
                </li>
                {/*------ cost link end ------*/}
                {/*------ materialkit link start ------*/}
                <li>
                  <Link
                    to="/materialkit"
                    onClick={() => {
                      document.body.classList.remove("add_menupnl");
                    }}
                  >
                    {t("Material Kit")}
                  </Link>
                </li>
                {/*------ materialkit link end ------*/}
                {/*------ concept link start ------*/}
                <li>
                  <Link
                    to="/concept"
                    onClick={() => {
                      document.body.classList.remove("add_menupnl");
                    }}
                  >
                    {t("Concept")}
                  </Link>
                </li>
                {/*------ concept link end ------*/}
                {/*------ Privilege link start ------*/}
                <li>
                  <Link
                    to="/roleprivilege"
                    onClick={() => {
                      document.body.classList.remove("add_menupnl");
                    }}
                  >
                    {t("Role Privilege")}
                  </Link>
                </li>
                {/*------ Privilege link end ------*/}
                <li>
                  <Link
                    to="/employeelogs"
                    onClick={() => {
                      document.body.classList.remove("add_menupnl");
                    }}
                  >
                    {t("Employee Logs")}
                  </Link>
                </li>

                {/* action logs */}
                <li>
                  <Link
                    to="/actionlogs"
                    onClick={() => {
                      document.body.classList.remove("add_menupnl");
                    }}
                  >
                    {t("Action Logs")}
                  </Link>
                </li>
              </ul>
            </li>
            {/* ------ administration dropdown link end ------ */}
            {/* ------ users link start ------ */}
            <li>
              <Link
                to="/usermanagement"
                onClick={() => {
                  document.body.classList.remove("add_menupnl");
                }}
              >
                <i className="material-icons-outlined">school</i>
                <span>{t("Users")}</span>
              </Link>
            </li>
            {/* ------ users link end ------ */}
            {/* ------ reservation link start ------ */}
            <li>
              <Link
                to="/reservation"
                onClick={() => {
                  document.body.classList.remove("add_menupnl");
                }}
              >
                <i className="material-icons-outlined">book_online</i>
                <span>{t("Reservations")}</span>
              </Link>
            </li>
            {/* ------ reservation link end ------ */}
            {/* ------ conversation link start ------ */}
            <li>
              <Link
                to="/conversation"
                onClick={() => {
                  document.body.classList.remove("add_menupnl");
                }}
              >
                <i className="material-icons-outlined">sms</i>
                <span>{t("Conversation")}</span>
              </Link>
            </li>
            {/* ------ conversation link end ------ */}
            {/* ------ agenda calendar link start ------ */}
            <li>
              <Link
                to="/agenda"
                onClick={() => {
                  document.body.classList.remove("add_menupnl");
                }}
              >
                <i className="material-icons-outlined">calendar_today</i>
                <span>{t("Agenda")}</span>
              </Link>
            </li>
            {/* ------ agenda calendar link end ------ */}
            {/* ------ agenda calendar link start ------ */}
            <li>
              <Link
                to="/salesorder"
                onClick={() => {
                  document.body.classList.remove("add_menupnl");
                }}
              >
                <i className="material-icons-outlined">payments</i>
                <span>{t("Salesorder")}</span>
              </Link>
            </li>
            <li>
              <Link
                to="/budget"
                onClick={() => {
                  document.body.classList.remove("add_menupnl");
                }}
              >
                <i className="material-icons-outlined">payments</i>
                <span>{t("Budget")}</span>
              </Link>
            </li>
            {/* ------ agenda calendar link end ------ */}
            {/* project management */}
            <li className="dropdown">
              {/*------ administration button start ------*/}
              <Link to="#" data-bs-toggle="dropdown">
                <i className="material-icons-outlined">article</i>
                <span>{t("Project Management")}</span>
                <span className="drop_arw">
                  <img src={assetImages.whiteArrow} alt="downarrow" />
                </span>
              </Link>
              {/*------ administration button end ------*/}
              <ul className="list_stye_none dropdown-menu">
                {/* daily task start */}
                <li>
                  <Link
                    to="/dailytask"
                    onClick={() => {
                      document.body.classList.remove("add_menupnl");
                    }}
                  >
                    {t("Daily Task")}
                  </Link>
                </li>
                {/* daily task end */}
                {/*------ Project link start ------*/}
                <li>
                  <Link
                    to="/project"
                    onClick={() => {
                      document.body.classList.remove("add_menupnl");
                    }}
                  >
                    {t("Project")}
                  </Link>
                </li>
                {/*------ Project link end ------*/}
                {/* ------ task link start ------ */}
                <li>
                  <Link
                    to="/task"
                    onClick={() => {
                      document.body.classList.remove("add_menupnl");
                    }}
                  >
                    <span>{t("Task")}</span>
                  </Link>
                </li>
                {/* ------ task link end ------ */}
              </ul>
            </li>
          </ul>
        </div>
      ) : (
        // If user not admin --------------------------------------------------------
        <div className="menu_list flex-fill overflow-auto">
          <ul className="list_stye_none">
            {/* ------ dashboard link start ------ */}
            <li>
              <Link
                to="/dashboard"
                onClick={() => {
                  document.body.classList.remove("add_menupnl");
                }}
              >
                <i className="material-icons-outlined">space_dashboard</i>
                <span>{t("Dashboard")}</span>
              </Link>
            </li>
            {/* ------ dashboard link end ------ */}
            {/* ------ administration dropdown link start ------ */}
            {menuPrivilege.administration && (
              <li className="dropdown">
                {/*------ administration button start ------*/}
                <Link to="#" data-bs-toggle="dropdown">
                  <i className="material-icons-outlined">manage_accounts</i>
                  <span>{t("Administration")}</span>
                  <span className="drop_arw">
                    <img src={assetImages.whiteArrow} alt="downarrow" />
                  </span>
                </Link>
                {/*------ administration button end ------*/}
                <ul className="list_stye_none dropdown-menu">
                  {/*------ mail template link start ------*/}
                  {menuPrivilege.mailtemplate && (
                    <li className={isModMailTemplateExist ? "active" : ""}>
                      <Link
                        to="/mailtemplate"
                        onClick={() => {
                          document.body.classList.remove("add_menupnl");
                        }}
                      >
                        {t("Mail Template")}
                      </Link>
                    </li>
                  )}
                  {/*------ mail template link end ------*/}
                  {/*------ reserve template link start ------*/}
                  {menuPrivilege.reservetemplate && (
                    <li>
                      <Link
                        to="/reservetemplate"
                        onClick={() => {
                          document.body.classList.remove("add_menupnl");
                        }}
                      >
                        {t("Reserve Template")}
                      </Link>
                    </li>
                  )}
                  {/*------ reserve template link end ------*/}
                  {/*------ question link start ------*/}
                  {menuPrivilege.question && (
                    <li>
                      <Link
                        to="/questions"
                        onClick={() => {
                          document.body.classList.remove("add_menupnl");
                        }}
                      >
                        {t("Questions")}
                      </Link>
                    </li>
                  )}
                  {/*------ question link end ------*/}
                  {/*------ holidaycalendar link start ------*/}
                  {menuPrivilege.holidaycalendar && (
                    <li>
                      <Link
                        onClick={() => {
                          document.body.classList.remove("add_menupnl");
                        }}
                        to="/holidaycalendar"
                      >
                        {t("Holiday Calendar")}
                      </Link>
                    </li>
                  )}
                  {/*------ holidaycalendar link end ------*/}
                  {/*------ activity link start ------*/}
                  {menuPrivilege.activity && (
                    <li>
                      <Link
                        onClick={() => {
                          document.body.classList.remove("add_menupnl");
                        }}
                        to="/activity"
                      >
                        {t("Activity")}
                      </Link>
                    </li>
                  )}
                  {/*------ activity link end ------*/}
                  {/*------ category link start ------*/}
                  {menuPrivilege.category && (
                    <li>
                      <Link
                        onClick={() => {
                          document.body.classList.remove("add_menupnl");
                        }}
                        to="/category"
                      >
                        {t("Category")}
                      </Link>
                    </li>
                  )}
                  {/*------ category link end ------*/}
                  {/*------ label link start ------*/}
                  {menuPrivilege.label && (
                    <li>
                      <Link
                        onClick={() => {
                          document.body.classList.remove("add_menupnl");
                        }}
                        to="/label"
                      >
                        {t("Label")}
                      </Link>
                    </li>
                  )}
                  {/*------ label link end ------*/}
                  {/*------ survey link start ------*/}
                  {menuPrivilege.survey && (
                    <li>
                      <Link
                        onClick={() => {
                          document.body.classList.remove("add_menupnl");
                        }}
                        to="/survey"
                      >
                        {t("Survey")}
                      </Link>
                    </li>
                  )}
                  {/*------ survey link end ------*/}
                  {/*------ cost link start ------*/}
                  {menuPrivilege.cost && (
                    <li>
                      <Link
                        onClick={() => {
                          document.body.classList.remove("add_menupnl");
                        }}
                        to="/cost"
                      >
                        {t("Cost")}
                      </Link>
                    </li>
                  )}
                  {/*------ cost link end ------*/}
                  {/*------ materialkit link start ------*/}
                  {menuPrivilege.materialkit && (
                    <li>
                      <Link
                        to="/materialkit"
                        onClick={() => {
                          document.body.classList.remove("add_menupnl");
                        }}
                      >
                        {t("Material Kit")}
                      </Link>
                    </li>
                  )}
                  {/*------ materialkit link end ------*/}
                  {/*------ concept link start ------*/}
                  {menuPrivilege.concept && (
                    <li>
                      <Link
                        to="/concept"
                        onClick={() => {
                          document.body.classList.remove("add_menupnl");
                        }}
                      >
                        {t("Concept")}
                      </Link>
                    </li>
                  )}
                  {/*------ concept link end ------*/}
                  {/*------ concept link start ------*/}
                  {menuPrivilege.roleprivilege && (
                    <li>
                      <Link
                        to="/roleprivilege"
                        onClick={() => {
                          document.body.classList.remove("add_menupnl");
                        }}
                      >
                        {t("Role Privilege")}
                      </Link>
                    </li>
                  )}
                  {/*------ concept link end ------*/}
                  {/* employee log */}
                  <li>
                    <Link
                      to="/employeelogs"
                      onClick={() => {
                        document.body.classList.remove("add_menupnl");
                      }}
                    >
                      {t("Employee Logs")}
                    </Link>
                  </li>

                  {/* action logs */}
                  <li>
                    <Link
                      to="/actionlogs"
                      onClick={() => {
                        document.body.classList.remove("add_menupnl");
                      }}
                    >
                      {t("Action Logs")}
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {/* ------ administration dropdown link end ------ */}
            {/* ------ users link start ------ */}
            {menuPrivilege.users && (
              <li>
                <Link
                  to="/usermanagement"
                  onClick={() => {
                    document.body.classList.remove("add_menupnl");
                  }}
                >
                  <i className="material-icons-outlined">school</i>
                  <span>{t("Users")}</span>
                </Link>
              </li>
            )}
            {/* ------ users link end ------ */}
            {/* ------ reservation link start ------ */}
            {menuPrivilege.reservation && (
              <li>
                <Link
                  to="/reservation"
                  onClick={() => {
                    document.body.classList.remove("add_menupnl");
                  }}
                >
                  <i className="material-icons-outlined">book_online</i>
                  <span>{t("Reservations")}</span>
                </Link>
              </li>
            )}
            {/* ------ reservation link end ------ */}
            {/* ------ conversation link start ------ */}
            {menuPrivilege.conversation && (
              <li>
                <Link
                  to="/conversation"
                  onClick={() => {
                    document.body.classList.remove("add_menupnl");
                  }}
                >
                  <i className="material-icons-outlined">sms</i>
                  <span>{t("Conversation")}</span>
                </Link>
              </li>
            )}
            {/* ------ conversation link end ------ */}
            {/* ------ agenda calendar link start ------ */}
            {menuPrivilege.agenda && (
              <li>
                <Link
                  to="/agenda"
                  onClick={() => {
                    document.body.classList.remove("add_menupnl");
                  }}
                >
                  <i className="material-icons-outlined">calendar_today</i>
                  <span>{t("Agenda")}</span>
                </Link>
              </li>
            )}
            {/* ------ agenda calendar link end ------ */}
            {/* ------ budget calendar link start ------ */}
            {menuPrivilege.budget && (
              <li>
                <Link
                  to="/salesorder"
                  onClick={() => {
                    document.body.classList.remove("add_menupnl");
                  }}
                >
                  <i className="material-icons-outlined">payments</i>
                  <span>{t("Budget")}</span>
                </Link>
              </li>
            )}
            {/* ------ budget calendar link end ------ */}
            {/* project management */}
            <li className="dropdown">
              {/*------ administration button start ------*/}
              <Link to="#" data-bs-toggle="dropdown">
                <i className="material-icons-outlined">article</i>
                <span>{t("Project Management")}</span>
                <span className="drop_arw">
                  <img src={assetImages.whiteArrow} alt="downarrow" />
                </span>
              </Link>
              {/*------ administration button end ------*/}
              <ul className="list_stye_none dropdown-menu">
                {/* daily task start */}
                <li>
                  <Link
                    to="/dailytask"
                    onClick={() => {
                      document.body.classList.remove("add_menupnl");
                    }}
                  >
                    {t("Daily Task")}
                  </Link>
                </li>
                {/* daily task end */}
                {/*------ Project link start ------*/}
                <li>
                  <Link
                    to="/project"
                    onClick={() => {
                      document.body.classList.remove("add_menupnl");
                    }}
                  >
                    {t("Project")}
                  </Link>
                </li>
                {/*------ Project link end ------*/}
                {/* ------ task link start ------ */}
                <li>
                  <Link
                    to="/task"
                    onClick={() => {
                      document.body.classList.remove("add_menupnl");
                    }}
                  >
                    <span>{t("Task")}</span>
                  </Link>
                </li>
                {/* ------ task link end ------ */}
              </ul>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};
export default LeftMenu;
