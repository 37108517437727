/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";

// import * as ReactDOM from 'react-dom';
import { useParams } from "react-router-dom";

/* importing schedular from kendo react schedular */
import {
  Scheduler,
  DayView,
  WeekView,
  MonthView,
  AgendaView,
  SchedulerItem,
} from "@progress/kendo-react-scheduler";

import {
  IntlProvider,
  load,
  LocalizationProvider,
  loadMessages,
} from "@progress/kendo-react-intl";

import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/es/numbers.json";
import currencies from "cldr-numbers-full/main/es/currencies.json";
import caGregorian from "cldr-dates-full/main/es/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/es/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/es/timeZoneNames.json";
load(
  likelySubtags,
  currencyData,
  weekData,
  numbers,
  currencies,
  caGregorian,
  dateFields,
  timeZoneNames
);

// import other translations for spainish
import esMessages from "data/Prod/esCalendarMessages.json";
loadMessages(esMessages, "es-ES");

//import translation function for language translation
import { useTranslation } from "react-i18next";

// import baseData from 'data/Scheduler.json';
import { Link } from "react-router-dom";

/*import url and gateway methods */
import { getData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

//import context
import { GlobalProvider } from "context/GlobalContext";
import UserProfileTabBreadCrumb from "../BreadCrumb/UserProfileTabBreadCrumb";

import { parseAdjust } from "helpers/ParseAdjust";

const CalenderTab = ({ reloadCalender, setReloadCalender, userData }) => {
  /* ========== get user id from url params ========= */
  const params = useParams();
  const token = localStorage.getItem("token"); // for token
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  //import conext
  const { reloadCalendarEvents, setReloadCalendarEvents, setEditCalendarData } =
    useContext(GlobalProvider);

  // default user data ------------------------------------
  const [calendarData, setCalendarData] = useState([]);
  const [responseReceived, setResponseReceived] = useState(false);
  const [holidayResponseReceived, setHolidayResponseReceived] = useState(false);

  const [isMassEdit, setIsMassEdit] = useState(false);
  const [editedIdsArr, setEditedIdsArr] = useState([]);

  const { t, i18n } = useTranslation(); // translation, language selection

  // get json data about date ------------------------------
  const currentYear = new Date().getFullYear();

  // function for get all calendar data
  const getAllCalendarData = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_SCHEDULE +
        `?token=${token}&user=${params.id}`;

      console.log("Url of calender list========>", requestUrl);

      const response = await getData(requestUrl);

      console.log(`response in calender list ==============>`, response.data);

      if (response.status) {
        const holidayScheduleData = await getHolidayCalendarData();

        const userScheduleData = response.data;

        const allData = [...holidayScheduleData, ...userScheduleData];

        setCalendarData(allData);

        setResponseReceived(true);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // function for get all calendar data
  const getHolidayCalendarData = async () => {
    let holidaySchedules = [];

    try {
      let requestUrl =
        url.API_BASEURL + url.API_GET_ALL_HOLIDAY_SCHEDULE + `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(
        `response of holiday calendar list ==============>`,
        response.data
      );
      if (response.status) {
        if (response.data) {
          holidaySchedules = response.data;
        }

        return holidaySchedules;
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // set response to match kendo scheduler event schema --------------------------------
  const kendoCalendarData = calendarData.map((dataItem) => ({
    id: dataItem._id,
    start: parseAdjust(dataItem.start, dataItem.starttime),
    end: parseAdjust(dataItem.end, dataItem.endtime),
    isAllDay: false,
    title: dataItem.title,
    name: dataItem.name,
    description: dataItem.description,
    ownerId: dataItem.availability,
    date: dataItem.date,
    availability: dataItem.availability,
    starttime: dataItem.starttime,
    endtime: dataItem.endtime,
    clientid: dataItem.clientid,
    conceptid: dataItem.conceptid,
    reserveid: dataItem.reserveid,
    shift: dataItem.shift,
  }));

  // const displayDate = new Date();

  /*---------- Handel Edit Calendar Schedule data ------------*/
  let scheduleItemHandler = (event) => {

    console.log('props.dataItem',event.target.props.dataItem);
    //assign schedule date in context

    if (event.target.props.dataItem.availability) {
      if (isMassEdit) {
        if (editedIdsArr.includes(event.target.props.dataItem.id)) {
          const updatedArr = editedIdsArr.filter(
            (item) =>
              item.toString() !== event.target.props.dataItem.id.toString()
          );
          setEditedIdsArr(updatedArr);
        } else {
          setEditedIdsArr([...editedIdsArr, event.target.props.dataItem.id]);
        }
      } else {

        setEditCalendarData([event.target.props.dataItem.id]);

        // if dataItem.reserveid is not null then show details popup
        // else show edit popup

        if(event.target.props.dataItem.reserveid){
          // show viewReserveSchedulePopup modal
          const viewReserveSchedulePopup = new window.bootstrap.Modal(
            document.getElementById("viewReserveSchedulePopup")
          );
          viewReserveSchedulePopup.show();
          


        }else{
          const offcanvas = new window.bootstrap.Offcanvas(
            document.getElementById("editSchedulePopup")
          );
          offcanvas.show();

        }
        

      }
    }
  };

  //function for trigger popup for mass edit
  const massEditHandler = () => {
    if (editedIdsArr.length > 0) {
      // console.log(editedIdsArr);
      setEditCalendarData(editedIdsArr);
      const offcanvas = new window.bootstrap.Offcanvas(
        document.getElementById("editSchedulePopup")
      );
      offcanvas.show();
      setIsMassEdit(false);
      setEditedIdsArr([]);
    }
  };

  /*--------- function for custom schedule ---------*/
  const CustomSchedule = (props) => (
    <SchedulerItem
      {...props}
      onClick={scheduleItemHandler}
      style={{
        ...props.style,
        background: editedIdsArr.includes(props.dataItem.id)
          ? "grey"
          : props.dataItem.availability == "1"
          ? "#32CD32"
          : props.dataItem.availability == "0"
          ? "#FF0000"
          : "#FA05E1",
      }}
    />
  );

  /* hook to reload list */
  useEffect(() => {
    if (reloadCalendarEvents) {
      getAllCalendarData();
      getHolidayCalendarData();
      setReloadCalendarEvents(false);

      console.log("calendarData >>>", calendarData);
    }
  }, [reloadCalendarEvents]);

  useEffect(() => {
    if (responseReceived === false) {
      getAllCalendarData();

      console.log("calendarData 1 >>>", calendarData);
    }
  }, [responseReceived]);

  return (
    <div className="profile_tabInnr">
      <UserProfileTabBreadCrumb moduleName="Calender" userData={userData} />

      {/* ------ calendar description and add calendar icon section start ------ */}
      <div className="calender_srch_top mb-3">
        <div className="d-flex align-items-center gap-2">
          <p className="mb-0">M : {t("Morning")}</p>
          <p className="mb-0">E : {t("Evening")}</p>
        </div>

        {userInfo?.role?.name == "admin" ||
        userInfo?.role?.name == "manager" ? (
          <div className="ms-auto d-flex gap-1 align-items-center">
            <div className="form-check ">
              <input
                className="form-check-input"
                type="checkbox"
                id="saveAsReserveTemplate"
                checked={isMassEdit}
                onChange={(e) => setIsMassEdit(e.target.checked)}
              />
              <p
                className="text-primary fw-medium"
                htmlFor="saveAsReserveTemplate"
              >
                {t("Update Mutiple Recods")}
              </p>
            </div>
            {isMassEdit && (
              <button className="btn btn-primary" onClick={massEditHandler}>
                {t("Done Selection")}
              </button>
            )}
            {/* {isMassEdit && <button className="btn btn-primary">Mass Edit</button>} */}
          </div>
        ) : null}

        {/* ---- add calendar icon ---- */}
        {userInfo?.role?.name == "admin" ||
        userInfo?.role?.name == "manager" ? (
          <div className="calender_addBtn ms-2">
            <Link
              to="#saveSchedulePopup"
              data-bs-toggle="offcanvas"
              className="btn btn-primary"
            >
              <i className="material-icons-outlined">add</i>
            </Link>
          </div>
        ) : null}
      </div>
      {/* ------ calendar description and add calendar icon section end ------ */}

      {/* <Scheduler
        data={kendoCalendarData}
        defaultDate={displayDate}
        item={CustomSchedule}
      >
        <MonthView />
        <WeekView />
        <DayView />
      </Scheduler> */}

      <LocalizationProvider
        language={i18n.language == "en" ? "en-EN" : "es-ES"}
      >
        <IntlProvider locale={i18n.language == "en" ? "en" : "es"}>
          <Scheduler
            data={kendoCalendarData}
            defaultDate={new Date()}
            item={CustomSchedule}
          >
            <MonthView title={t("Month")} />

            <WeekView title={t("Week")} />
            <DayView title={t("Day")} />
            <AgendaView title={t("Agenda")} />
          </Scheduler>
        </IntlProvider>
      </LocalizationProvider>
    </div>
  );
};

export default CalenderTab;
